import * as React from 'react';
import { ls } from '../../i18n/translations';
import {
  capitalize,
  dayjs,
  getAlarmMetadata,
  getEventType,
  skipAlarmKey,
} from '../../helpers/utils';
import { defaultMUIDataTableOptions } from '../../helpers/datatableUtils';
import MUIDataTable, { MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables';
import { AlarmHistory, MachineType, Module } from '../../graphql/codegen/graphql-react';
import { AlarmsType } from '../../graphql/dataTypes';
import Flex from '../ui/Flex';
import { Box } from '@mui/material';
import { colors } from '../../theme';
import { Body2 } from '../ui/Typography';

interface DeviceAlarmsTableProps {
  module: Module;
  data: AlarmHistory[];
}

const options: MUIDataTableOptions = {
  ...defaultMUIDataTableOptions,
};

const DeviceAlarmsTable: React.FC<DeviceAlarmsTableProps> = ({ data, module }) => {
  const dateTimeFile = dayjs().format('YYYY-MM-DD_HH_mm');

  const tableDevices = (
    [...(data || [])].map((al, i) => {
      const alarms = al.alarms as AlarmsType;
      const activeAlarms: string[] = [];
      if (alarms) {
        const alarmsKeys = Object.keys(alarms);
        if (alarmsKeys.length === 0) {
          return null;
        } else {
          for (let i = 0; i < alarmsKeys.length; i++) {
            const alarmsKey = alarmsKeys[i];
            const skipAlarm = skipAlarmKey(alarmsKey, module);
            // fix only for Cycleau and keys 'flow', 'onOffKey'
            // fix only for NovaNsp and key 'stop'
            // fix only for Ef315 and key 'stop'
            // consider the opposite value because the flow alarm is in
            // active state when it is false (on backend) and disabled when true
            // see file 'Stati' on gdrive
            let checkOpposite = false;
            if (module.type === MachineType.Cycleau) {
              if (alarmsKey === 'flow' || alarmsKey === 'onOffKey') {
                checkOpposite = true;
              }
            }
            if (module.type === MachineType.NovaNsp) {
              if (alarmsKey === 'stop') {
                checkOpposite = true;
              }
            }
            if (module.type === MachineType.Ef315) {
              if (alarmsKey === 'stop') {
                checkOpposite = true;
              }
            }
            if (
              !skipAlarm &&
              (checkOpposite
                ? // @ts-ignore
                  !alarms[alarmsKey]
                : // @ts-ignore
                  alarms[alarmsKey])
            ) {
              activeAlarms.push(alarmsKey);
            }
          }
        }
      }
      return {
        dateAndTime: al.createdAt ? dayjs(al.createdAt).format('YYYY-MM-DD HH:mm') : null,
        activeAlarms,
      };
    }) || []
  ).filter((el) => el) as {
    dateAndTime: string;
    activeAlarms: string[];
  }[];

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'dateAndTime',
      label: ls.dateAndTime.toUpperCase(),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'activeAlarms',
      label: ls.events.toUpperCase(),
      options: {
        filter: true,
        sort: true,
        // eslint-disable-next-line react/display-name
        customBodyRender: (value: string[], tableMeta, updateValue) => {
          if (!Array.isArray(value) || value.length === 0) {
            return (
              <Flex justifyContent='flex-start' flexWrap='wrap'>
                <Box bgcolor={colors.green} borderRadius={100} py={0.5} px={2} mr={1} mt={0.5}>
                  <Body2
                    style={{
                      color: '#fff',
                    }}
                  >
                    {capitalize(ls.alarmsFixed)}
                  </Body2>
                </Box>
              </Flex>
            );
          }
          return (
            <Flex justifyContent='flex-start' flexWrap='wrap'>
              {value.map((v, i) => {
                let value = ls.getString('alarm_new_' + v) || ls.getString('alarm_' + v) || v;
                const alarms = (data[tableMeta.rowIndex]?.alarms || {}) as AlarmsType;
                // @ts-ignore
                const alarmValue = alarms?.[v];
                let eventType = getEventType(v);
                if (module.type && module.modelVersion) {
                  const amd = getAlarmMetadata(v, module.type, module.modelVersion);
                  if (amd) {
                    value = amd.translatedName;
                    if (ls.toolStatus.toLowerCase() === amd.translatedName.toLowerCase()) {
                      if (
                        module.type === MachineType.Ew2 ||
                        module.type === MachineType.Ew2Pool ||
                        module.type === MachineType.Nova2Pool
                      ) {
                        let onOffKeyValue = alarms?.['onOffKeyValue'];
                        if (module.type === MachineType.Ew2) {
                          // the value on ew2 is from the first 2 bits (we maintain the same behavior)
                          if (onOffKeyValue === 0) {
                            onOffKeyValue = 256;
                          } else if (onOffKeyValue === 1) {
                            onOffKeyValue = 257;
                          } else if (onOffKeyValue === 2) {
                            onOffKeyValue = 14850;
                          }
                        }
                        if (onOffKeyValue === 257) {
                          value = amd.translatedName + ` (${ls.on.toLowerCase()})`;
                        } else if (onOffKeyValue === 14850 || onOffKeyValue === 15362) {
                          value = amd.translatedName + ` (${ls.alarm.toLowerCase()})`;
                          eventType = 'alarm';
                        } else {
                          value = amd.translatedName + ` (${ls.off.toLowerCase()})`;
                        }
                      }
                    }
                  }
                }
                return (
                  <Box
                    key={i}
                    bgcolor={
                      eventType === 'event'
                        ? colors.blue
                        : eventType === 'warn'
                        ? colors.orange
                        : colors.red
                    }
                    borderRadius={100}
                    py={0.5}
                    px={2}
                    mr={1}
                    mt={0.5}
                  >
                    <Body2
                      style={{
                        color: '#fff',
                      }}
                    >
                      {value}
                    </Body2>
                  </Box>
                );
              })}
            </Flex>
          );
        },
      },
    },
  ];

  return (
    <MUIDataTable
      title=''
      data={tableDevices}
      columns={columns}
      options={{
        ...options,
        downloadOptions: {
          filename: `DeviceEvents_${dateTimeFile}.csv`,
        },
        rowsPerPage: localStorage.getItem('alarms_rowsPerPage')
          ? Number(localStorage.getItem('alarms_rowsPerPage'))
          : 10,
        onChangeRowsPerPage: (numberOfRows) => {
          localStorage.setItem('alarms_rowsPerPage', `${numberOfRows}`);
        },
      }}
    />
  );
};

export default DeviceAlarmsTable;
