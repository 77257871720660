import clone from 'just-clone';
import { MachineType, MachineVersion, Maybe, Module } from '../graphql/codegen/graphql-react';
import { ModuleSettingsType, ParametersType } from '../graphql/dataTypes';
import { ls } from '../i18n/translations';
import { capitalize, isNotNullAndNotUndefined } from './utils';

export interface Parameter {
  idx: string;
  name: string;
  type: MachineType;
  version: MachineVersion;
  min: number;
  max: number;
  translationKey: string;
  selectValues?: string[];
  hideMinMax?: boolean;
  selectValuesOffset?: number;
  disabled?: boolean;
  isFavorite?: boolean;
}

let cycleauFirstParameters: Parameter[] = [
  {
    name: 'setpointPH',
    idx: 'P01',
    type: MachineType.Cycleau,
    version: MachineVersion.First,
    min: 6.5,
    max: 8.5,
    translationKey: 'setpointPHParamDesc',
  },
  {
    name: 'setpointCl2',
    idx: 'P02',
    type: MachineType.Cycleau,
    version: MachineVersion.First,
    min: 0.1,
    max: 10,
    translationKey: 'setpointCl2ParamDesc',
  },
  {
    name: 'minPHAlarm',
    idx: 'P03',
    type: MachineType.Cycleau,
    version: MachineVersion.First,
    min: 0,
    max: 14,
    translationKey: 'minPHAlarmParamDesc',
  },
  {
    name: 'maxPHAlarm',
    idx: 'P04',
    type: MachineType.Cycleau,
    version: MachineVersion.First,
    min: 0,
    max: 14,
    translationKey: 'maxPHAlarmParamDesc',
  },
  {
    name: 'minFreeChlorine',
    idx: 'P05',
    type: MachineType.Cycleau,
    version: MachineVersion.First,
    min: 0,
    max: 10,
    translationKey: 'minFreeChlorineParamDesc',
  },
  {
    name: 'maxFreeChlorine',
    idx: 'P06',
    type: MachineType.Cycleau,
    version: MachineVersion.First,
    min: 0,
    max: 10,
    translationKey: 'maxFreeChlorineParamDesc',
  },
  {
    name: 'settingTypePH',
    idx: 'P07',
    type: MachineType.Cycleau,
    version: MachineVersion.First,
    min: 0,
    max: 1,
    selectValues: [ls.onOff.toUpperCase(), capitalize(ls.proportional)],
    translationKey: 'settingTypePHParamDesc',
  },
  {
    name: 'settingTypeCl2',
    idx: 'P08',
    type: MachineType.Cycleau,
    version: MachineVersion.First,
    min: 0,
    max: 1,
    selectValues: [ls.onOff.toUpperCase(), capitalize(ls.proportional)],
    translationKey: 'settingTypeCl2ParamDesc',
  },
  {
    name: 'hysteresisPH',
    idx: 'P09',
    type: MachineType.Cycleau,
    version: MachineVersion.First,
    min: 0.05,
    max: 14,
    translationKey: 'hysteresisPHParamDesc',
  },
  {
    name: 'hysteresisCl2',
    idx: 'P10',
    type: MachineType.Cycleau,
    version: MachineVersion.First,
    min: 0.05,
    max: 10,
    translationKey: 'hysteresisCl2ParamDesc',
  },
  {
    name: 'sensePH',
    idx: 'P11',
    type: MachineType.Cycleau,
    version: MachineVersion.First,
    min: 0,
    max: 1,
    selectValues: [capitalize(ls.acid), capitalize(ls.alkaline)],
    translationKey: 'sensePHParamDesc',
  },
  {
    name: 'startDelay',
    idx: 'P12',
    type: MachineType.Cycleau,
    version: MachineVersion.First,
    min: 5,
    max: 20 * 60,
    translationKey: 'startDelayParamDesc',
  },
  {
    name: 'flowDelay',
    idx: 'P13',
    type: MachineType.Cycleau,
    version: MachineVersion.First,
    min: 5,
    max: 20 * 60,
    translationKey: 'flowDelayParamDesc',
  },
  {
    name: 'maxInjectionTime',
    idx: 'P14',
    type: MachineType.Cycleau,
    version: MachineVersion.First,
    min: 0,
    max: 24 * 60,
    translationKey: 'maxInjectionTimeParamDesc',
  },
  {
    name: 'pHTankLvl',
    idx: 'P15',
    type: MachineType.Cycleau,
    version: MachineVersion.First,
    min: 0,
    max: 1,
    selectValues: [capitalize(ls.active), capitalize(ls.deactivated)],
    translationKey: 'pHTankLvlParamDesc',
  },
  {
    name: 'chlorineTankLvl',
    idx: 'P16',
    type: MachineType.Cycleau,
    version: MachineVersion.First,
    min: 0,
    max: 1,
    selectValues: [capitalize(ls.active), capitalize(ls.deactivated)],
    translationKey: 'chlorineTankLvlParamDesc',
  },
  {
    name: 'flowSensor',
    idx: 'P17',
    type: MachineType.Cycleau,
    version: MachineVersion.First,
    min: 0,
    max: 1,
    selectValues: [capitalize(ls.active), capitalize(ls.deactivated)],
    translationKey: 'flowSensorParamDesc',
  },
  {
    name: 'language',
    idx: 'P18',
    type: MachineType.Cycleau,
    version: MachineVersion.First,
    min: 0,
    max: 3,
    selectValues: [
      capitalize(ls.italian),
      capitalize(ls.english),
      capitalize(ls.french),
      capitalize(ls.spanish),
    ],
    translationKey: 'languageParamDesc',
  },
  {
    name: 'alarmRelay',
    idx: 'P19',
    type: MachineType.Cycleau,
    version: MachineVersion.First,
    min: 0,
    max: 1,
    selectValues: [capitalize(ls.active), capitalize(ls.deactivated)],
    translationKey: 'alarmRelayParamDesc',
  },
  {
    name: 'serialLine',
    idx: 'P20',
    type: MachineType.Cycleau,
    version: MachineVersion.First,
    min: 0,
    max: 1,
    selectValues: [capitalize(ls.ascii), capitalize(ls.modbus)],
    disabled: true,
    translationKey: 'serialLineParamDesc',
  },
  {
    name: 'serialLineCommSpeed',
    idx: 'P21',
    type: MachineType.Cycleau,
    version: MachineVersion.First,
    min: 0,
    max: 2,
    selectValues: ['9600 BPS', '19200 BPS', '38400 BPS'],
    disabled: true,
    translationKey: 'serialLineCommSpeedParamDesc',
  },
  {
    name: 'modbusCommAddr',
    idx: 'P22',
    type: MachineType.Cycleau,
    version: MachineVersion.First,
    min: 1,
    max: 126,
    disabled: true,
    translationKey: 'modbusCommAddrParamDesc',
  },
  {
    name: 'password',
    idx: 'P23',
    type: MachineType.Cycleau,
    version: MachineVersion.First,
    min: 0,
    max: 999,
    disabled: true,
    translationKey: 'passwordParamDesc',
  },
];
cycleauFirstParameters = cycleauFirstParameters.map((el) => ({ ...el, isFavorite: true }));
let cycleauProParameters: Parameter[] = [
  {
    name: 'setpointPH',
    idx: 'P01',
    type: MachineType.Cycleau,
    version: MachineVersion.Pro,
    min: 6.5,
    max: 8.5,
    translationKey: 'setpointPHParamDesc',
  },
  {
    name: 'setpointCl2',
    idx: 'P02',
    type: MachineType.Cycleau,
    version: MachineVersion.Pro,
    min: 0.1,
    max: 10,
    translationKey: 'setpointCl2ParamDesc',
  },
  {
    name: 'minPHAlarm',
    idx: 'P03',
    type: MachineType.Cycleau,
    version: MachineVersion.Pro,
    min: 0,
    max: 14,
    translationKey: 'minPHAlarmParamDesc',
  },
  {
    name: 'maxPHAlarm',
    idx: 'P04',
    type: MachineType.Cycleau,
    version: MachineVersion.Pro,
    min: 0,
    max: 14,
    translationKey: 'maxPHAlarmParamDesc',
  },
  {
    name: 'minFreeChlorine',
    idx: 'P05',
    type: MachineType.Cycleau,
    version: MachineVersion.Pro,
    min: 0,
    max: 10,
    translationKey: 'minFreeChlorineParamDesc',
  },
  {
    name: 'maxFreeChlorine',
    idx: 'P06',
    type: MachineType.Cycleau,
    version: MachineVersion.Pro,
    min: 0,
    max: 10,
    translationKey: 'maxFreeChlorineParamDesc',
  },
  {
    name: 'maxCombChlorine',
    idx: 'P07',
    type: MachineType.Cycleau,
    version: MachineVersion.Pro,
    min: 0,
    max: 1,
    translationKey: 'maxCombChlorineParamDesc',
  },
  {
    name: 'settingTypePH',
    idx: 'P08',
    type: MachineType.Cycleau,
    version: MachineVersion.Pro,
    min: 0,
    max: 1,
    selectValues: [ls.onOff.toUpperCase(), capitalize(ls.proportional)],
    translationKey: 'settingTypePHParamDesc',
  },
  {
    name: 'settingTypeCl2',
    idx: 'P09',
    type: MachineType.Cycleau,
    version: MachineVersion.Pro,
    min: 0,
    max: 1,
    selectValues: [ls.onOff.toUpperCase(), capitalize(ls.proportional)],
    translationKey: 'settingTypeCl2ParamDesc',
  },
  {
    name: 'hysteresisPH',
    idx: 'P10',
    type: MachineType.Cycleau,
    version: MachineVersion.Pro,
    min: 0.05,
    max: 14,
    translationKey: 'hysteresisPHParamDesc',
  },
  {
    name: 'hysteresisCl2',
    idx: 'P11',
    type: MachineType.Cycleau,
    version: MachineVersion.Pro,
    min: 0.05,
    max: 10,
    translationKey: 'hysteresisCl2ParamDesc',
  },
  {
    name: 'sensePH',
    idx: 'P12',
    type: MachineType.Cycleau,
    version: MachineVersion.Pro,
    min: 0,
    max: 1,
    selectValues: [capitalize(ls.acid), capitalize(ls.alkaline)],
    translationKey: 'sensePHParamDesc',
  },
  {
    name: 'startDelay',
    idx: 'P13',
    type: MachineType.Cycleau,
    version: MachineVersion.Pro,
    min: 5,
    max: 20 * 60,
    translationKey: 'startDelayParamDesc',
  },
  {
    name: 'flowDelay',
    idx: 'P14',
    type: MachineType.Cycleau,
    version: MachineVersion.Pro,
    min: 5,
    max: 20 * 60,
    translationKey: 'flowDelayParamDesc',
  },
  {
    name: 'maxInjectionTime',
    idx: 'P15',
    type: MachineType.Cycleau,
    version: MachineVersion.Pro,
    min: 0,
    max: 24 * 60,
    translationKey: 'maxInjectionTimeParamDesc',
  },
  {
    name: 'pHTankLvl',
    idx: 'P16',
    type: MachineType.Cycleau,
    version: MachineVersion.Pro,
    min: 0,
    max: 1,
    selectValues: [capitalize(ls.active), capitalize(ls.deactivated)],
    translationKey: 'pHTankLvlParamDesc',
  },
  {
    name: 'chlorineTankLvl',
    idx: 'P17',
    type: MachineType.Cycleau,
    version: MachineVersion.Pro,
    min: 0,
    max: 1,
    selectValues: [capitalize(ls.active), capitalize(ls.deactivated)],
    translationKey: 'chlorineTankLvlParamDesc',
  },
  {
    name: 'flowSensor',
    idx: 'P18',
    type: MachineType.Cycleau,
    version: MachineVersion.Pro,
    min: 0,
    max: 1,
    selectValues: [capitalize(ls.active), capitalize(ls.deactivated)],
    translationKey: 'flowSensorParamDesc',
  },
  {
    name: 'totalChlorine',
    idx: 'P19',
    type: MachineType.Cycleau,
    version: MachineVersion.Pro,
    min: 0,
    max: 1,
    selectValues: [capitalize(ls.active), capitalize(ls.deactivated)],
    translationKey: 'totalChlorineParamDesc',
  },
  {
    name: 'language',
    idx: 'P20',
    type: MachineType.Cycleau,
    version: MachineVersion.Pro,
    min: 0,
    max: 3,
    selectValues: [
      capitalize(ls.italian),
      capitalize(ls.english),
      capitalize(ls.french),
      capitalize(ls.spanish),
    ],
    translationKey: 'languageParamDesc',
  },
  {
    name: 'alarmRelay',
    idx: 'P21',
    type: MachineType.Cycleau,
    version: MachineVersion.Pro,
    min: 0,
    max: 1,
    selectValues: [capitalize(ls.active), capitalize(ls.deactivated)],
    translationKey: 'alarmRelayParamDesc',
  },
  {
    name: 'serialLine',
    idx: 'P22',
    type: MachineType.Cycleau,
    version: MachineVersion.Pro,
    min: 0,
    max: 1,
    selectValues: [capitalize(ls.ascii), capitalize(ls.modbus)],
    disabled: true,
    translationKey: 'serialLineParamDesc',
  },
  {
    name: 'serialLineCommSpeed',
    idx: 'P23',
    type: MachineType.Cycleau,
    version: MachineVersion.Pro,
    min: 0,
    max: 2,
    selectValues: ['9600 BPS', '19200 BPS', '38400 BPS'],
    disabled: true,
    translationKey: 'serialLineCommSpeedParamDesc',
  },
  {
    name: 'modbusCommAddr',
    idx: 'P24',
    type: MachineType.Cycleau,
    version: MachineVersion.Pro,
    min: 1,
    max: 126,
    disabled: true,
    translationKey: 'modbusCommAddrParamDesc',
  },
  {
    name: 'password',
    idx: 'P25',
    type: MachineType.Cycleau,
    version: MachineVersion.Pro,
    min: 0,
    max: 999,
    disabled: true,
    translationKey: 'passwordParamDesc',
  },
];
cycleauProParameters = cycleauProParameters.map((el) => ({ ...el, isFavorite: true }));

const Mc14evoPHRxCle12TcParameters: Parameter[] = [
  {
    name: 'P01',
    idx: 'P01',
    translationKey: 'P01MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    min: 0,
    max: 5,
    selectValues: [
      'pH',
      'Redox',
      capitalize(ls.notAvailable),
      capitalize(ls.freeChlorine) + ' DPD',
      capitalize(ls.temperature),
      capitalize(ls.totalChlorine) + ' DPD',
    ],
    selectValuesOffset: 1,
  },
  {
    name: 'P02',
    idx: 'P02',
    translationKey: 'P02MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    min: 0,
    max: 9,
    selectValues: [
      ls.disabled,
      ls.closingOverthresholds,
      ls.openingOverthreshold,
      'PWM ' + ls.upward,
      'PWM ' + ls.downward,
      ls.alarm + ' NA',
      ls.alarm + ' NC',
      'PFM ' + ls.upward,
      'PFM ' + ls.downward,
      ls.hourlyOperation,
    ],
  },
  {
    name: 'P03',
    idx: 'P03',
    translationKey: 'P03MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    min: -10,
    max: 20,
  },
  {
    name: 'P04',
    idx: 'P04',
    translationKey: 'P04MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    min: 0,
    max: 5,
  },
  {
    name: 'P05',
    idx: 'P05',
    translationKey: 'P05MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    min: 0,
    max: 1800,
  },
  {
    name: 'P06',
    idx: 'P06',
    translationKey: 'P06MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    min: 0,
    max: 1800,
  },
  {
    name: 'P07',
    idx: 'P07',
    translationKey: 'P07MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    min: 0,
    max: 599,
  },
  {
    name: 'P08',
    idx: 'P08',
    translationKey: 'P08MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    min: 0,
    max: 255,
  },
  {
    name: 'P09',
    idx: 'P09',
    translationKey: 'P09MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    min: 0,
    max: 5,
    selectValues: [
      'pH',
      'Redox',
      capitalize(ls.notAvailable),
      capitalize(ls.freeChlorine) + ' DPD',
      capitalize(ls.temperature),
      capitalize(ls.totalChlorine) + ' DPD',
    ],
    selectValuesOffset: 1,
  },
  {
    name: 'P10',
    idx: 'P10',
    translationKey: 'P10MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    min: 0,
    max: 9,
    selectValues: [
      ls.disabled,
      ls.closingOverthresholds,
      ls.openingOverthreshold,
      'PWM ' + ls.upward,
      'PWM ' + ls.downward,
      ls.alarm + ' NA',
      ls.alarm + ' NC',
      'PFM ' + ls.upward,
      'PFM ' + ls.downward,
      ls.hourlyOperation,
    ],
  },
  {
    name: 'P11',
    idx: 'P11',
    translationKey: 'P11MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    min: -10,
    max: 20,
  },
  {
    name: 'P12',
    idx: 'P12',
    translationKey: 'P12MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    min: 0,
    max: 5,
  },
  {
    name: 'P13',
    idx: 'P13',
    translationKey: 'P13MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    min: 0,
    max: 1800,
  },
  {
    name: 'P14',
    idx: 'P14',
    translationKey: 'P14MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    min: 0,
    max: 1800,
  },
  {
    name: 'P15',
    idx: 'P15',
    translationKey: 'P15MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    min: 0,
    max: 599,
  },
  {
    name: 'P16',
    idx: 'P16',
    translationKey: 'P16MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    min: 0,
    max: 255,
  },
  {
    name: 'P17',
    idx: 'P17',
    translationKey: 'P17MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P18',
    idx: 'P18',
    translationKey: 'P18MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P19',
    idx: 'P19',
    translationKey: 'P19MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P20',
    idx: 'P20',
    translationKey: 'P20MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P21',
    idx: 'P21',
    translationKey: 'P21MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P22',
    idx: 'P22',
    translationKey: 'P22MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P23',
    idx: 'P23',
    translationKey: 'P23MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P24',
    idx: 'P24',
    translationKey: 'P24MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P25',
    idx: 'P25',
    translationKey: 'P25MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P26',
    idx: 'P26',
    translationKey: 'P26MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P27',
    idx: 'P27',
    translationKey: 'P27MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P28',
    idx: 'P28',
    translationKey: 'P28MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P29',
    idx: 'P29',
    translationKey: 'P29MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P30',
    idx: 'P30',
    translationKey: 'P30MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P31',
    idx: 'P31',
    translationKey: 'P31MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P32',
    idx: 'P32',
    translationKey: 'P32MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P33',
    idx: 'P33',
    translationKey: 'P33MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P34',
    idx: 'P34',
    translationKey: 'P34MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P35',
    idx: 'P35',
    translationKey: 'P35MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P36',
    idx: 'P36',
    translationKey: 'P36MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P37',
    idx: 'P37',
    translationKey: 'P37MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P38',
    idx: 'P38',
    translationKey: 'P38MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P39',
    idx: 'P39',
    translationKey: 'P39MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P40',
    idx: 'P40',
    translationKey: 'P40MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P41',
    idx: 'P41',
    translationKey: 'P41MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P42',
    idx: 'P42',
    translationKey: 'P42MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P43',
    idx: 'P43',
    translationKey: 'P43MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P44',
    idx: 'P44',
    translationKey: 'P44MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P45',
    idx: 'P45',
    translationKey: 'P45MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P46',
    idx: 'P46',
    translationKey: 'P46MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P47',
    idx: 'P47',
    translationKey: 'P47MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P48',
    idx: 'P48',
    translationKey: 'P48MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P49',
    idx: 'P49',
    translationKey: 'P49MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P50',
    idx: 'P50',
    translationKey: 'P50MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P51',
    idx: 'P51',
    translationKey: 'P51MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P52',
    idx: 'P52',
    translationKey: 'P52MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P53',
    idx: 'P53',
    translationKey: 'P53MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P54',
    idx: 'P54',
    translationKey: 'P54MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P55',
    idx: 'P55',
    translationKey: 'P55MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P56',
    idx: 'P56',
    translationKey: 'P56MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P57',
    idx: 'P57',
    translationKey: 'P57MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P58',
    idx: 'P58',
    translationKey: 'P58MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P59',
    idx: 'P59',
    translationKey: 'P59MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P60',
    idx: 'P60',
    translationKey: 'P60MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P61',
    idx: 'P61',
    translationKey: 'P61MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P62',
    idx: 'P62',
    translationKey: 'P62MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P63',
    idx: 'P63',
    translationKey: 'P63MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P64',
    idx: 'P64',
    translationKey: 'P64MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P65',
    idx: 'P65',
    translationKey: 'P65MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P66',
    idx: 'P66',
    translationKey: 'P66MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P67',
    idx: 'P67',
    translationKey: 'P67MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P68',
    idx: 'P68',
    translationKey: 'P68MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P69',
    idx: 'P69',
    translationKey: 'P69MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P70',
    idx: 'P70',
    translationKey: 'P70MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P71',
    idx: 'P71',
    translationKey: 'P71MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P72',
    idx: 'P72',
    translationKey: 'P72MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P73',
    idx: 'P73',
    translationKey: 'P73MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P74',
    idx: 'P74',
    translationKey: 'P74MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P75',
    idx: 'P75',
    translationKey: 'P75MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P76',
    idx: 'P76',
    translationKey: 'P76MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P77',
    idx: 'P77',
    translationKey: 'P77MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P78',
    idx: 'P78',
    translationKey: 'P78MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P79',
    idx: 'P79',
    translationKey: 'P79MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P80',
    idx: 'P80',
    translationKey: 'P80MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P81',
    idx: 'P81',
    translationKey: 'P81MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P82',
    idx: 'P82',
    translationKey: 'P82MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P83',
    idx: 'P83',
    translationKey: 'P83MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P84',
    idx: 'P84',
    translationKey: 'P84MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P85',
    idx: 'P85',
    translationKey: 'P85MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P86',
    idx: 'P86',
    translationKey: 'P86MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P87',
    idx: 'P87',
    translationKey: 'P87MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P88',
    idx: 'P88',
    translationKey: 'P88MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P89',
    idx: 'P89',
    translationKey: 'P89MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P90',
    idx: 'P90',
    translationKey: 'P90MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P91',
    idx: 'P91',
    translationKey: 'P91MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P92',
    idx: 'P92',
    translationKey: 'P92MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P93',
    idx: 'P93',
    translationKey: 'P93MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  // {
  //   name: 'P94',
  //   idx: 'P94',
  //   translationKey: 'P94MC14ParamDesc',
  //   type: MachineType.Mc14Evo,
  //   version: MachineVersion.Mc14evoPHRxCle12Tc,
  //   max: 65535,
  //   min: -65535,
  //   hideMinMax: true,
  // },
];

const NovaNsp161CMinParameters: Parameter[] = [
  {
    name: 'P01',
    idx: 'P01',
    translationKey: 'P01NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    min: 0,
    max: 1,
    selectValues: [capitalize(ls.italian), capitalize(ls.english)],
  },
  {
    name: 'P02',
    idx: 'P02',
    translationKey: 'P02NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P03',
    idx: 'P03',
    translationKey: 'P03NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P04',
    idx: 'P04',
    translationKey: 'P04NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P05',
    idx: 'P05',
    translationKey: 'P05NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P06',
    idx: 'P06',
    translationKey: 'P06NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P07',
    idx: 'P07',
    translationKey: 'P07NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 180,
    min: 0,
    isFavorite: true,
  },
  {
    name: 'P08',
    idx: 'P08',
    translationKey: 'P08NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P09',
    idx: 'P09',
    translationKey: 'P09NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P10',
    idx: 'P10',
    translationKey: 'P10NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P11',
    idx: 'P11',
    translationKey: 'P11NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
    isFavorite: true,
  },
  {
    name: 'P12',
    idx: 'P12',
    translationKey: 'P12NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
    isFavorite: true,
  },
  {
    name: 'P13',
    idx: 'P13',
    translationKey: 'P13NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P14',
    idx: 'P14',
    translationKey: 'P14NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P15',
    idx: 'P15',
    translationKey: 'P15NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P16',
    idx: 'P16',
    translationKey: 'P16NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P17',
    idx: 'P17',
    translationKey: 'P17NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P18',
    idx: 'P18',
    translationKey: 'P18NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P19',
    idx: 'P19',
    translationKey: 'P19NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P20',
    idx: 'P20',
    translationKey: 'P20NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P21',
    idx: 'P21',
    translationKey: 'P21NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P22',
    idx: 'P22',
    translationKey: 'P22NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P23',
    idx: 'P23',
    translationKey: 'P23NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P24',
    idx: 'P24',
    translationKey: 'P24NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P25',
    idx: 'P25',
    translationKey: 'P25NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P26',
    idx: 'P26',
    translationKey: 'P26NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P27',
    idx: 'P27',
    translationKey: 'P27NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P28',
    idx: 'P28',
    translationKey: 'P28NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P29',
    idx: 'P29',
    translationKey: 'P29NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P30',
    idx: 'P30',
    translationKey: 'P30NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P31',
    idx: 'P31',
    translationKey: 'P31NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P32',
    idx: 'P32',
    translationKey: 'P32NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P33',
    idx: 'P33',
    translationKey: 'P33NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P34',
    idx: 'P34',
    translationKey: 'P34NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P35',
    idx: 'P35',
    translationKey: 'P35NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P36',
    idx: 'P36',
    translationKey: 'P36NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P37',
    idx: 'P37',
    translationKey: 'P37NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P38',
    idx: 'P38',
    translationKey: 'P38NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P39',
    idx: 'P39',
    translationKey: 'P39NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P40',
    idx: 'P40',
    translationKey: 'P40NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P41',
    idx: 'P41',
    translationKey: 'P41NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P42',
    idx: 'P42',
    translationKey: 'P42NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P43',
    idx: 'P43',
    translationKey: 'P43NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P44',
    idx: 'P44',
    translationKey: 'P44NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P45',
    idx: 'P45',
    translationKey: 'P45NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P46',
    idx: 'P46',
    translationKey: 'P46NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P47',
    idx: 'P47',
    translationKey: 'P47NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P48',
    idx: 'P48',
    translationKey: 'P48NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P49',
    idx: 'P49',
    translationKey: 'P49NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P50',
    idx: 'P50',
    translationKey: 'P50NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P51',
    idx: 'P51',
    translationKey: 'P51NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P52',
    idx: 'P52',
    translationKey: 'P52NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P53',
    idx: 'P53',
    translationKey: 'P53NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P54',
    idx: 'P54',
    translationKey: 'P54NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P55',
    idx: 'P55',
    translationKey: 'P55NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P56',
    idx: 'P56',
    translationKey: 'P56NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P57',
    idx: 'P57',
    translationKey: 'P57NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P58',
    idx: 'P58',
    translationKey: 'P58NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P59',
    idx: 'P59',
    translationKey: 'P59NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P60',
    idx: 'P60',
    translationKey: 'P60NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P61',
    idx: 'P61',
    translationKey: 'P61NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P62',
    idx: 'P62',
    translationKey: 'P62NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P63',
    idx: 'P63',
    translationKey: 'P63NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P64',
    idx: 'P64',
    translationKey: 'P64NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P65',
    idx: 'P65',
    translationKey: 'P65NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P66',
    idx: 'P66',
    translationKey: 'P66NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P67',
    idx: 'P67',
    translationKey: 'P67NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P68',
    idx: 'P68',
    translationKey: 'P68NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P69',
    idx: 'P69',
    translationKey: 'P69NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P70',
    idx: 'P70',
    translationKey: 'P70NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P71',
    idx: 'P71',
    translationKey: 'P71NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P72',
    idx: 'P72',
    translationKey: 'P72NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P73',
    idx: 'P73',
    translationKey: 'P73NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P74',
    idx: 'P74',
    translationKey: 'P74NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P75',
    idx: 'P75',
    translationKey: 'P75NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P76',
    idx: 'P76',
    translationKey: 'P76NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P77',
    idx: 'P77',
    translationKey: 'P77NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P78',
    idx: 'P78',
    translationKey: 'P78NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P79',
    idx: 'P79',
    translationKey: 'P79NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P80',
    idx: 'P80',
    translationKey: 'P80NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P81',
    idx: 'P81',
    translationKey: 'P81NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P82',
    idx: 'P82',
    translationKey: 'P82NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P83',
    idx: 'P83',
    translationKey: 'P83NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P84',
    idx: 'P84',
    translationKey: 'P84NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P85',
    idx: 'P85',
    translationKey: 'P85NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P86',
    idx: 'P86',
    translationKey: 'P86NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P87',
    idx: 'P87',
    translationKey: 'P87NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P88',
    idx: 'P88',
    translationKey: 'P88NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P89',
    idx: 'P89',
    translationKey: 'P89NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P90',
    idx: 'P90',
    translationKey: 'P90NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P91',
    idx: 'P91',
    translationKey: 'P91NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P92',
    idx: 'P92',
    translationKey: 'P92NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P93',
    idx: 'P93',
    translationKey: 'P93NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P94',
    idx: 'P94',
    translationKey: 'P94NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P95',
    idx: 'P95',
    translationKey: 'P95NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
];

const Mc14evoPHRxCacTcParameters = [
  ...Mc14evoPHRxCle12TcParameters.map((el) => ({
    ...el,
    version: MachineVersion.Mc14evoPHRxCacTc,
  })),
];

const Mc14evo_4CanaliTcParameters = [
  ...Mc14evoPHRxCle12TcParameters.map((el) => ({
    ...el,
    version: MachineVersion.Mc14evo_4CanaliTc,
  })),
];

const Mco14_3SezCl2Parameters = [
  ...Mc14evoPHRxCle12TcParameters.map((el) => ({
    ...el,
    type: MachineType.Mco14Evo,
    version: MachineVersion.Mco14_3SezCl2,
  })),
];

const Mco14_5SezCl2ClTClCParameters = [
  ...Mc14evoPHRxCle12TcParameters.map((el) => ({
    ...el,
    type: MachineType.Mco14Evo,
    version: MachineVersion.Mco14_5SezCl2ClTClC,
  })),
];

const NovaNsp161MaParameters = [
  ...NovaNsp161CMinParameters.map((el) => ({
    ...el,
    version: MachineVersion.Nsp161Ma,
  })),
];

const NovaNsp162PHRxParameters = [
  ...NovaNsp161CMinParameters.map((el) => ({
    ...el,
    version: MachineVersion.Nsp162PHRx,
  })),
];

const NovaNsp163ACle11_12Parameters = [
  ...NovaNsp161CMinParameters.map((el) => ({
    ...el,
    version: MachineVersion.Nsp163ACle11_12,
  })),
];

const NovaNsp163BCle16Parameters = [
  ...NovaNsp161CMinParameters.map((el) => ({
    ...el,
    version: MachineVersion.Nsp163BCle16,
  })),
];

const EF315PHRxTcParameters: Parameter[] = [
  {
    name: 'P001',
    idx: 'P001',
    translationKey: 'P01EFParamDesc',
    type: MachineType.Ef315,
    version: MachineVersion.PHRxTc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P002',
    idx: 'P002',
    translationKey: 'P02EFParamDesc',
    type: MachineType.Ef315,
    version: MachineVersion.PHRxTc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P003',
    idx: 'P003',
    translationKey: 'P03EFParamDesc',
    type: MachineType.Ef315,
    version: MachineVersion.PHRxTc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
    isFavorite: true,
  },
  {
    name: 'P004',
    idx: 'P004',
    translationKey: 'P04EFParamDesc',
    type: MachineType.Ef315,
    version: MachineVersion.PHRxTc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
    isFavorite: true,
  },
  {
    name: 'P005',
    idx: 'P005',
    translationKey: 'P05EFParamDesc',
    type: MachineType.Ef315,
    version: MachineVersion.PHRxTc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
    isFavorite: true,
  },
  {
    name: 'P006',
    idx: 'P006',
    translationKey: 'P06EFParamDesc',
    type: MachineType.Ef315,
    version: MachineVersion.PHRxTc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P007',
    idx: 'P007',
    translationKey: 'P07EFParamDesc',
    type: MachineType.Ef315,
    version: MachineVersion.PHRxTc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P008',
    idx: 'P008',
    translationKey: 'P08EFParamDesc',
    type: MachineType.Ef315,
    version: MachineVersion.PHRxTc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P009',
    idx: 'P009',
    translationKey: 'P09EFParamDesc',
    type: MachineType.Ef315,
    version: MachineVersion.PHRxTc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P010',
    idx: 'P010',
    translationKey: 'P10EFParamDesc',
    type: MachineType.Ef315,
    version: MachineVersion.PHRxTc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
    isFavorite: true,
  },
  {
    name: 'P011',
    idx: 'P011',
    translationKey: 'P11EFParamDesc',
    type: MachineType.Ef315,
    version: MachineVersion.PHRxTc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
    isFavorite: true,
  },
  {
    name: 'P012',
    idx: 'P012',
    translationKey: 'P12EFParamDesc',
    type: MachineType.Ef315,
    version: MachineVersion.PHRxTc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
    isFavorite: true,
  },
  {
    name: 'P013',
    idx: 'P013',
    translationKey: 'P13EFParamDesc',
    type: MachineType.Ef315,
    version: MachineVersion.PHRxTc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P014',
    idx: 'P014',
    translationKey: 'P14EFParamDesc',
    type: MachineType.Ef315,
    version: MachineVersion.PHRxTc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P015',
    idx: 'P015',
    translationKey: 'P15EFParamDesc',
    type: MachineType.Ef315,
    version: MachineVersion.PHRxTc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P016',
    idx: 'P016',
    translationKey: 'P16EFParamDesc',
    type: MachineType.Ef315,
    version: MachineVersion.PHRxTc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P017',
    idx: 'P017',
    translationKey: 'P17EFParamDesc',
    type: MachineType.Ef315,
    version: MachineVersion.PHRxTc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P018',
    idx: 'P018',
    translationKey: 'P18EFParamDesc',
    type: MachineType.Ef315,
    version: MachineVersion.PHRxTc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P019',
    idx: 'P019',
    translationKey: 'P19EFParamDesc',
    type: MachineType.Ef315,
    version: MachineVersion.PHRxTc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P020',
    idx: 'P020',
    translationKey: 'P20EFParamDesc',
    type: MachineType.Ef315,
    version: MachineVersion.PHRxTc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P021',
    idx: 'P021',
    translationKey: 'P21EFParamDesc',
    type: MachineType.Ef315,
    version: MachineVersion.PHRxTc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P022',
    idx: 'P022',
    translationKey: 'P22EFParamDesc',
    type: MachineType.Ef315,
    version: MachineVersion.PHRxTc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P023',
    idx: 'P023',
    translationKey: 'P23EFParamDesc',
    type: MachineType.Ef315,
    version: MachineVersion.PHRxTc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P024',
    idx: 'P024',
    translationKey: 'P24EFParamDesc',
    type: MachineType.Ef315,
    version: MachineVersion.PHRxTc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P025',
    idx: 'P025',
    translationKey: 'P25EFParamDesc',
    type: MachineType.Ef315,
    version: MachineVersion.PHRxTc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P026',
    idx: 'P026',
    translationKey: 'P26EFParamDesc',
    type: MachineType.Ef315,
    version: MachineVersion.PHRxTc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P027',
    idx: 'P027',
    translationKey: 'P27EFParamDesc',
    type: MachineType.Ef315,
    version: MachineVersion.PHRxTc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P028',
    idx: 'P028',
    translationKey: 'P28EFParamDesc',
    type: MachineType.Ef315,
    version: MachineVersion.PHRxTc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P029',
    idx: 'P029',
    translationKey: 'P29EFParamDesc',
    type: MachineType.Ef315,
    version: MachineVersion.PHRxTc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P030',
    idx: 'P030',
    translationKey: 'P30EFParamDesc',
    type: MachineType.Ef315,
    version: MachineVersion.PHRxTc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P031',
    idx: 'P031',
    translationKey: 'P31EFParamDesc',
    type: MachineType.Ef315,
    version: MachineVersion.PHRxTc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P032',
    idx: 'P032',
    translationKey: 'P32EFParamDesc',
    type: MachineType.Ef315,
    version: MachineVersion.PHRxTc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P033',
    idx: 'P033',
    translationKey: 'P33EFParamDesc',
    type: MachineType.Ef315,
    version: MachineVersion.PHRxTc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P034',
    idx: 'P034',
    translationKey: 'P34EFParamDesc',
    type: MachineType.Ef315,
    version: MachineVersion.PHRxTc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
];

const EW2PoolPHOrpTcParameters: Parameter[] = [
  {
    name: 'setpointM1OnOff',
    idx: 'P01',
    translationKey: 'setpointM1OnOffParamDesc',
    type: MachineType.Ew2Pool,
    version: MachineVersion.PHOrpTc,
    max: 14,
    min: 0,
    isFavorite: true,
  },
  {
    name: 'setpointM1Pwm',
    idx: 'P02',
    translationKey: 'setpointM1PwmParamDesc',
    type: MachineType.Ew2Pool,
    version: MachineVersion.PHOrpTc,
    max: 14,
    min: 0,
    isFavorite: true,
  },
  {
    name: 'setpointM1Pfm',
    idx: 'P03',
    translationKey: 'setpointM1PfmParamDesc',
    type: MachineType.Ew2Pool,
    version: MachineVersion.PHOrpTc,
    max: 14,
    min: 0,
    isFavorite: true,
  },
  {
    name: 'minFreqM1',
    idx: 'P04',
    translationKey: 'minFreqM1ParamDesc',
    type: MachineType.Ew2Pool,
    version: MachineVersion.PHOrpTc,
    max: 65535,
    min: 0,
    isFavorite: true,
  },
  {
    name: 'maxFreqM1',
    idx: 'P05',
    translationKey: 'maxFreqM1ParamDesc',
    type: MachineType.Ew2Pool,
    version: MachineVersion.PHOrpTc,
    max: 180,
    min: 0,
    isFavorite: true,
  },
  {
    name: 'minLimitM1',
    idx: 'P06',
    translationKey: 'minLimitM1ParamDesc',
    type: MachineType.Ew2Pool,
    version: MachineVersion.PHOrpTc,
    max: 65535,
    min: 0,
    isFavorite: true,
  },
  {
    name: 'maxLimitM1',
    idx: 'P07',
    translationKey: 'maxLimitM1ParamDesc',
    type: MachineType.Ew2Pool,
    version: MachineVersion.PHOrpTc,
    max: 14,
    min: 0,
    isFavorite: true,
  },
  {
    name: 'setpointM2OnOff',
    idx: 'P08',
    translationKey: 'setpointM2OnOffParamDesc',
    type: MachineType.Ew2Pool,
    version: MachineVersion.PHOrpTc,
    max: 2000,
    min: 0,
    isFavorite: true,
  },
  {
    name: 'setpointM2Pwm',
    idx: 'P09',
    translationKey: 'setpointM2PwmParamDesc',
    type: MachineType.Ew2Pool,
    version: MachineVersion.PHOrpTc,
    max: 2000,
    min: 0,
    isFavorite: true,
  },
  {
    name: 'setpointM2Pfm',
    idx: 'P10',
    translationKey: 'setpointM2PfmParamDesc',
    type: MachineType.Ew2Pool,
    version: MachineVersion.PHOrpTc,
    max: 2000,
    min: 0,
    isFavorite: true,
  },
  {
    name: 'minFreqM2',
    idx: 'P11',
    translationKey: 'minFreqM2ParamDesc',
    type: MachineType.Ew2Pool,
    version: MachineVersion.PHOrpTc,
    max: 65535,
    min: 0,
    isFavorite: true,
  },
  {
    name: 'maxFreqM2',
    idx: 'P12',
    translationKey: 'maxFreqM2ParamDesc',
    type: MachineType.Ew2Pool,
    version: MachineVersion.PHOrpTc,
    max: 180,
    min: 0,
    isFavorite: true,
  },
  {
    name: 'minLimitM2',
    idx: 'P13',
    translationKey: 'minLimitM2ParamDesc',
    type: MachineType.Ew2Pool,
    version: MachineVersion.PHOrpTc,
    max: 65535,
    min: 0,
    isFavorite: true,
  },
  {
    name: 'maxLimitM2',
    idx: 'P14',
    translationKey: 'maxLimitM2ParamDesc',
    type: MachineType.Ew2Pool,
    version: MachineVersion.PHOrpTc,
    max: 2000,
    min: 0,
    isFavorite: true,
  },
  {
    name: 'setpointEcoMode',
    idx: 'P15',
    translationKey: 'setpointEcoModeParamDesc',
    type: MachineType.Ew2Pool,
    version: MachineVersion.PHOrpTc,
    max: 2000,
    min: 0,
    isFavorite: true,
  },
  {
    name: 'propBandM1OnOff',
    idx: 'P16',
    translationKey: 'propBandM1OnOffParamDesc',
    type: MachineType.Ew2Pool,
    version: MachineVersion.PHOrpTc,
    max: 6,
    min: 0.05,
    isFavorite: false,
  },
  {
    name: 'propBandM1Pwm',
    idx: 'P17',
    translationKey: 'propBandM1PwmParamDesc',
    type: MachineType.Ew2Pool,
    version: MachineVersion.PHOrpTc,
    max: 14,
    min: 0.05,
    isFavorite: false,
  },
  {
    name: 'propBandM1Pfm',
    idx: 'P18',
    translationKey: 'propBandM1PfmParamDesc',
    type: MachineType.Ew2Pool,
    version: MachineVersion.PHOrpTc,
    max: 14,
    min: 0.05,
    isFavorite: false,
  },
  {
    name: 'dosageDirM1OnOff',
    idx: 'P19',
    translationKey: 'dosageDirM1OnOffParamDesc',
    type: MachineType.Ew2Pool,
    version: MachineVersion.PHOrpTc,
    hideMinMax: true,
    isFavorite: false,
    min: 0,
    max: 1,
    selectValues: [capitalize(ls.acidification), capitalize(ls.alkalization)],
  },
  {
    name: 'dosageDirM1Pwm',
    idx: 'P20',
    translationKey: 'dosageDirM1PwmParamDesc',
    type: MachineType.Ew2Pool,
    version: MachineVersion.PHOrpTc,
    hideMinMax: true,
    isFavorite: false,
    min: 0,
    max: 1,
    selectValues: [capitalize(ls.acidification), capitalize(ls.alkalization)],
  },
  {
    name: 'dosageDirM1Pfm',
    idx: 'P21',
    translationKey: 'dosageDirM1PfmParamDesc',
    type: MachineType.Ew2Pool,
    version: MachineVersion.PHOrpTc,
    hideMinMax: true,
    isFavorite: false,
    min: 0,
    max: 1,
    selectValues: [capitalize(ls.acidification), capitalize(ls.alkalization)],
  },
  {
    name: 'maxDosageTimeM1OnOff',
    idx: 'P22',
    translationKey: 'maxDosageTimeM1OnOffParamDesc',
    type: MachineType.Ew2Pool,
    version: MachineVersion.PHOrpTc,
    max: 480,
    min: 0,
    isFavorite: false,
  },
  {
    name: 'maxDosageTimeM1Pwm',
    idx: 'P23',
    translationKey: 'maxDosageTimeM1PwmParamDesc',
    type: MachineType.Ew2Pool,
    version: MachineVersion.PHOrpTc,
    max: 480,
    min: 0,
    isFavorite: false,
  },
  {
    name: 'maxDosageTimeM1Pfm',
    idx: 'P24',
    translationKey: 'maxDosageTimeM1PfmParamDesc',
    type: MachineType.Ew2Pool,
    version: MachineVersion.PHOrpTc,
    max: 480,
    min: 0,
    isFavorite: false,
  },
  {
    name: 'propBandM2OnOff',
    idx: 'P25',
    translationKey: 'propBandM2OnOffParamDesc',
    type: MachineType.Ew2Pool,
    version: MachineVersion.PHOrpTc,
    max: 2000,
    min: 5,
    isFavorite: false,
  },
  {
    name: 'propBandM2Pwm',
    idx: 'P26',
    translationKey: 'propBandM2PwmParamDesc',
    type: MachineType.Ew2Pool,
    version: MachineVersion.PHOrpTc,
    max: 2000,
    min: 5,
    isFavorite: false,
  },
  {
    name: 'propBandM2Pfm',
    idx: 'P27',
    translationKey: 'propBandM2PfmParamDesc',
    type: MachineType.Ew2Pool,
    version: MachineVersion.PHOrpTc,
    max: 2000,
    min: 5,
    isFavorite: false,
  },
  {
    name: 'dosageDirM2OnOff',
    idx: 'P28',
    translationKey: 'dosageDirM2OnOffParamDesc',
    type: MachineType.Ew2Pool,
    version: MachineVersion.PHOrpTc,
    hideMinMax: true,
    isFavorite: false,
    min: 0,
    max: 1,
    selectValues: [capitalize(ls.dechlorination), capitalize(ls.chlorination)],
  },
  {
    name: 'dosageDirM2Pwm',
    idx: 'P29',
    translationKey: 'dosageDirM2PwmParamDesc',
    type: MachineType.Ew2Pool,
    version: MachineVersion.PHOrpTc,
    hideMinMax: true,
    isFavorite: false,
    min: 0,
    max: 1,
    selectValues: [capitalize(ls.dechlorination), capitalize(ls.chlorination)],
  },
  {
    name: 'dosageDirM2Pfm',
    idx: 'P30',
    translationKey: 'dosageDirM2PfmParamDesc',
    type: MachineType.Ew2Pool,
    version: MachineVersion.PHOrpTc,
    hideMinMax: true,
    isFavorite: false,
    min: 0,
    max: 1,
    selectValues: [capitalize(ls.dechlorination), capitalize(ls.chlorination)],
  },
  {
    name: 'maxDosageTimeM2OnOff',
    idx: 'P31',
    translationKey: 'maxDosageTimeM2OnOffParamDesc',
    type: MachineType.Ew2Pool,
    version: MachineVersion.PHOrpTc,
    max: 480,
    min: 0,
    isFavorite: false,
  },
  {
    name: 'maxDosageTimeM2Pwm',
    idx: 'P32',
    translationKey: 'maxDosageTimeM2PwmParamDesc',
    type: MachineType.Ew2Pool,
    version: MachineVersion.PHOrpTc,
    max: 480,
    min: 0,
    isFavorite: false,
  },
  {
    name: 'maxDosageTimeM2Pfm',
    idx: 'P33',
    translationKey: 'maxDosageTimeM2PfmParamDesc',
    type: MachineType.Ew2Pool,
    version: MachineVersion.PHOrpTc,
    max: 480,
    min: 0,
    isFavorite: false,
  },
];

const EW22CanaliTCParameters: Parameter[] = [
  {
    name: 'checkSetpointM1',
    idx: 'P01',
    translationKey: 'P01EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    hideMinMax: true,
    max: 3,
    min: 0,
    isFavorite: true,
    selectValues: [capitalize(ls.disabled), 'On/Off', 'PWM', capitalize(ls.workInt)],
  },
  {
    name: 'setpointM1OnOff',
    idx: 'P02',
    translationKey: 'P02EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    max: 9999,
    min: 0,
    isFavorite: true,
  },
  {
    name: 'setpointM1Pwm',
    idx: 'P03',
    translationKey: 'P03EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    max: 9999,
    min: 0,
    isFavorite: true,
  },
  {
    name: 'P04',
    idx: 'P04',
    translationKey: 'P04EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    max: 9999,
    min: 0,
    isFavorite: true,
  },
  {
    name: 'P05',
    idx: 'P05',
    translationKey: 'P05EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    max: 9999,
    min: 0,
    isFavorite: true,
  },
  {
    name: 'P06',
    idx: 'P06',
    translationKey: 'P06EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    hideMinMax: true,
    max: 3,
    min: 0,
    isFavorite: true,
    selectValues: [capitalize(ls.disabled), 'On/Off', 'PWM', capitalize(ls.workInt)],
  },
  {
    name: 'P07',
    idx: 'P07',
    translationKey: 'P07EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    max: 9999,
    min: 0,
    isFavorite: true,
  },
  {
    name: 'P08',
    idx: 'P08',
    translationKey: 'P08EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    max: 9999,
    min: 0,
    isFavorite: true,
  },
  {
    name: 'P09',
    idx: 'P09',
    translationKey: 'P09EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    max: 9999,
    min: 0,
    isFavorite: true,
  },
  {
    name: 'P10',
    idx: 'P10',
    translationKey: 'P10EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    max: 9999,
    min: 0,
    isFavorite: true,
  },
  {
    name: 'DOpfmM1Enabled',
    idx: 'P11',
    translationKey: 'P11EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    hideMinMax: true,
    max: 1,
    min: 0,
    isFavorite: true,
    selectValues: [capitalize(ls.disabled), 'PFM'],
  },
  {
    name: 'P12',
    idx: 'P12',
    translationKey: 'P12EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    max: 9999,
    min: 0,
    isFavorite: true,
  },
  {
    name: 'P13',
    idx: 'P13',
    translationKey: 'P13EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    max: 9999,
    min: 0,
    isFavorite: true,
  },
  {
    name: 'P14',
    idx: 'P14',
    translationKey: 'P14EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    max: 9999,
    min: 0,
    isFavorite: true,
  },
  {
    name: 'P15',
    idx: 'P15',
    translationKey: 'P15EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    hideMinMax: true,
    max: 1,
    min: 0,
    isFavorite: true,
    selectValues: [capitalize(ls.disabled), 'PFM'],
  },
  {
    name: 'P16',
    idx: 'P16',
    translationKey: 'P16EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    max: 9999,
    min: 0,
    isFavorite: true,
  },
  {
    name: 'P17',
    idx: 'P17',
    translationKey: 'P17EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    max: 9999,
    min: 0,
    isFavorite: true,
  },
  {
    name: 'P18',
    idx: 'P18',
    translationKey: 'P18EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    max: 9999,
    min: 0,
    isFavorite: true,
  },
  {
    name: 'P19',
    idx: 'P19',
    translationKey: 'P19EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    hideMinMax: true,
    max: 2,
    min: 0,
    isFavorite: true,
    selectValues: [capitalize(ls.disabled), capitalize(ls.repetition), capitalize(ls.regulation)],
  },
  {
    name: 'P20a',
    idx: 'P20a',
    translationKey: 'P20aEW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    hideMinMax: true,
    max: 1,
    min: 0,
    isFavorite: true,
    selectValues: ['0/20mA (Rip)', '4/20mA (Rip)'],
  },
  {
    name: 'P20b',
    idx: 'P20b',
    translationKey: 'P20bEW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    hideMinMax: true,
    max: 1,
    min: 0,
    isFavorite: true,
    selectValues: ['0/20mA (Reg)', '4/20mA (Reg)'],
  },
  {
    name: 'P21',
    idx: 'P21',
    translationKey: 'P21EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    max: 9999,
    min: 0,
    isFavorite: true,
  },
  {
    name: 'minLimitM1',
    idx: 'P22',
    translationKey: 'P22EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    max: 9999,
    min: 0,
    isFavorite: true,
  },
  {
    name: 'maxLimitM1',
    idx: 'P23',
    translationKey: 'P23EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    max: 9999,
    min: 0,
    isFavorite: true,
  },
  {
    name: 'P24',
    idx: 'P24',
    translationKey: 'P24EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    max: 9999,
    min: 0,
    isFavorite: true,
  },
  {
    name: 'P25',
    idx: 'P25',
    translationKey: 'P25EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    max: 9999,
    min: 0,
    isFavorite: true,
  },
  {
    name: 'P26',
    idx: 'P26',
    translationKey: 'P26EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    max: 9999,
    min: 0,
    isFavorite: true,
  },
  {
    name: 'checkSetpointM2',
    idx: 'P27',
    translationKey: 'P27EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    hideMinMax: true,
    max: 3,
    min: 0,
    isFavorite: true,
    selectValues: [capitalize(ls.disabled), 'On/Off', 'PWM', capitalize(ls.workInt)],
  },
  {
    name: 'setpointM2OnOff',
    idx: 'P28',
    translationKey: 'P28EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    max: 9999,
    min: 0,
    isFavorite: true,
  },
  {
    name: 'setpointM2Pwm',
    idx: 'P29',
    translationKey: 'P29EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    max: 9999,
    min: 0,
    isFavorite: true,
  },
  {
    name: 'P30',
    idx: 'P30',
    translationKey: 'P30EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    max: 9999,
    min: 0,
    isFavorite: true,
  },
  {
    name: 'P31',
    idx: 'P31',
    translationKey: 'P31EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    max: 9999,
    min: 0,
    isFavorite: true,
  },
  {
    name: 'P32',
    idx: 'P32',
    translationKey: 'P32EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    hideMinMax: true,
    max: 3,
    min: 0,
    isFavorite: true,
    selectValues: [capitalize(ls.disabled), 'On/Off', 'PWM', capitalize(ls.workInt)],
  },
  {
    name: 'P33',
    idx: 'P33',
    translationKey: 'P33EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    max: 9999,
    min: 0,
    isFavorite: true,
  },
  {
    name: 'P34',
    idx: 'P34',
    translationKey: 'P34EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    max: 9999,
    min: 0,
    isFavorite: true,
  },
  {
    name: 'P35',
    idx: 'P35',
    translationKey: 'P35EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    max: 9999,
    min: 0,
    isFavorite: true,
  },
  {
    name: 'P36',
    idx: 'P36',
    translationKey: 'P36EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    max: 9999,
    min: 0,
    isFavorite: true,
  },
  {
    name: 'DOpfmM2Enabled',
    idx: 'P37',
    translationKey: 'P37EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    hideMinMax: true,
    max: 1,
    min: 0,
    isFavorite: true,
    selectValues: [capitalize(ls.disabled), 'PFM'],
  },
  {
    name: 'P38',
    idx: 'P38',
    translationKey: 'P38EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    max: 9999,
    min: 0,
    isFavorite: true,
  },
  {
    name: 'P39',
    idx: 'P39',
    translationKey: 'P39EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    max: 9999,
    min: 0,
    isFavorite: true,
  },
  {
    name: 'P40',
    idx: 'P40',
    translationKey: 'P40EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    max: 9999,
    min: 0,
    isFavorite: true,
  },
  {
    name: 'P41',
    idx: 'P41',
    translationKey: 'P41EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    hideMinMax: true,
    max: 1,
    min: 0,
    isFavorite: true,
    selectValues: [capitalize(ls.disabled), 'PFM'],
  },
  {
    name: 'P42',
    idx: 'P42',
    translationKey: 'P42EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    max: 9999,
    min: 0,
    isFavorite: true,
  },
  {
    name: 'P43',
    idx: 'P43',
    translationKey: 'P43EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    max: 9999,
    min: 0,
    isFavorite: true,
  },
  {
    name: 'P44',
    idx: 'P44',
    translationKey: 'P44EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    max: 9999,
    min: 0,
    isFavorite: true,
  },
  {
    name: 'P45',
    idx: 'P45',
    translationKey: 'P45EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    hideMinMax: true,
    max: 2,
    min: 0,
    isFavorite: true,
    selectValues: [capitalize(ls.disabled), capitalize(ls.repetition), capitalize(ls.regulation)],
  },
  {
    name: 'P46a',
    idx: 'P46a',
    translationKey: 'P46aEW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    hideMinMax: true,
    max: 1,
    min: 0,
    isFavorite: true,
    selectValues: ['0/20mA (Rip)', '4/20mA (Rip)'],
  },
  {
    name: 'P46b',
    idx: 'P46b',
    translationKey: 'P46bEW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    hideMinMax: true,
    max: 1,
    min: 0,
    isFavorite: true,
    selectValues: ['0/20mA (Reg)', '4/20mA (Reg)'],
  },
  {
    name: 'P47',
    idx: 'P47',
    translationKey: 'P47EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    max: 9999,
    min: 0,
    isFavorite: true,
  },
  {
    name: 'minLimitM2',
    idx: 'P48',
    translationKey: 'P48EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    max: 9999,
    min: 0,
    isFavorite: true,
  },
  {
    name: 'maxLimitM2',
    idx: 'P49',
    translationKey: 'P49EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    max: 9999,
    min: 0,
    isFavorite: true,
  },
  {
    name: 'P50',
    idx: 'P50',
    translationKey: 'P50EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    max: 9999,
    min: 0,
    isFavorite: true,
  },
  {
    name: 'P51',
    idx: 'P51',
    translationKey: 'P51EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    max: 9999,
    min: 0,
    isFavorite: true,
  },
  {
    name: 'P52',
    idx: 'P52',
    translationKey: 'P52EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    max: 9999,
    min: 0,
    isFavorite: true,
  },
  // secondary parameters
  {
    name: 'P53',
    idx: 'P53',
    translationKey: 'P53EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    max: 9999,
    min: 0,
    isFavorite: false,
  },
  {
    name: 'P54',
    idx: 'P54',
    translationKey: 'P54EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    max: 9999,
    min: 0,
    isFavorite: false,
  },
  {
    name: 'P55',
    idx: 'P55',
    translationKey: 'P55EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    hideMinMax: true,
    max: 1,
    min: 0,
    isFavorite: false,
    selectValues: [
      capitalize(ls.acidificationDechlorinationReduction),
      capitalize(ls.alkalizationChlorinationOxidation),
    ],
  },
  {
    name: 'P56',
    idx: 'P56',
    translationKey: 'P56EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    hideMinMax: true,
    max: 1,
    min: 0,
    isFavorite: false,
    selectValues: [
      capitalize(ls.acidificationDechlorinationReduction),
      capitalize(ls.alkalizationChlorinationOxidation),
    ],
  },
  {
    name: 'P57',
    idx: 'P57',
    translationKey: 'P57EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    max: 9999,
    min: 0,
    isFavorite: false,
  },
  {
    name: 'P58',
    idx: 'P58',
    translationKey: 'P58EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    max: 9999,
    min: 0,
    isFavorite: false,
  },
  {
    name: 'P59',
    idx: 'P59',
    translationKey: 'P59EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    hideMinMax: true,
    max: 1,
    min: 0,
    isFavorite: false,
    selectValues: ['NA', 'NC'],
  },
  {
    name: 'P60',
    idx: 'P60',
    translationKey: 'P60EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    max: 9999,
    min: 0,
    isFavorite: false,
  },
  {
    name: 'P61',
    idx: 'P61',
    translationKey: 'P61EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    max: 9999,
    min: 0,
    isFavorite: false,
  },
  {
    name: 'P62',
    idx: 'P62',
    translationKey: 'P62EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    hideMinMax: true,
    max: 1,
    min: 0,
    isFavorite: false,
    selectValues: [
      capitalize(ls.acidificationDechlorinationReduction),
      capitalize(ls.alkalizationChlorinationOxidation),
    ],
  },
  {
    name: 'P63',
    idx: 'P63',
    translationKey: 'P63EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    hideMinMax: true,
    max: 1,
    min: 0,
    isFavorite: false,
    selectValues: [
      capitalize(ls.acidificationDechlorinationReduction),
      capitalize(ls.alkalizationChlorinationOxidation),
    ],
  },
  {
    name: 'P64',
    idx: 'P64',
    translationKey: 'P64EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    max: 9999,
    min: 0,
    isFavorite: false,
  },
  {
    name: 'P65',
    idx: 'P65',
    translationKey: 'P65EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    max: 9999,
    min: 0,
    isFavorite: false,
  },
  {
    name: 'P66',
    idx: 'P66',
    translationKey: 'P66EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    hideMinMax: true,
    max: 1,
    min: 0,
    isFavorite: false,
    selectValues: ['NA', 'NC'],
  },
  {
    name: 'P67',
    idx: 'P67',
    translationKey: 'P67EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    max: 9999,
    min: 0,
    isFavorite: false,
  },
  {
    name: 'P68',
    idx: 'P68',
    translationKey: 'P68EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    hideMinMax: true,
    max: 1,
    min: 0,
    isFavorite: false,
    selectValues: [
      capitalize(ls.acidificationDechlorinationReduction),
      capitalize(ls.alkalizationChlorinationOxidation),
    ],
  },
  {
    name: 'P69',
    idx: 'P69',
    translationKey: 'P69EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    max: 9999,
    min: 0,
    isFavorite: false,
  },
  {
    name: 'P70',
    idx: 'P70',
    translationKey: 'P70EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    max: 9999,
    min: 0,
    isFavorite: false,
  },
  {
    name: 'P71',
    idx: 'P71',
    translationKey: 'P71EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    hideMinMax: true,
    max: 1,
    min: 0,
    isFavorite: false,
    selectValues: [
      capitalize(ls.acidificationDechlorinationReduction),
      capitalize(ls.alkalizationChlorinationOxidation),
    ],
  },
  {
    name: 'P72',
    idx: 'P72',
    translationKey: 'P72EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    max: 9999,
    min: 0,
    isFavorite: false,
  },
  {
    name: 'P73',
    idx: 'P73',
    translationKey: 'P73EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    max: 9999,
    min: 0,
    isFavorite: false,
  },
  {
    name: 'P74',
    idx: 'P74',
    translationKey: 'P74EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    max: 9999,
    min: 0,
    isFavorite: false,
  },
  {
    name: 'P75',
    idx: 'P75',
    translationKey: 'P75EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    max: 9999,
    min: 0,
    isFavorite: false,
  },
  {
    name: 'P76',
    idx: 'P76',
    translationKey: 'P76EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    hideMinMax: true,
    max: 1,
    min: 0,
    isFavorite: false,
    selectValues: [
      capitalize(ls.acidificationDechlorinationReduction),
      capitalize(ls.alkalizationChlorinationOxidation),
    ],
  },
  {
    name: 'P77',
    idx: 'P77',
    translationKey: 'P77EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    max: 9999,
    min: 0,
    isFavorite: false,
  },
  {
    name: 'P78',
    idx: 'P78',
    translationKey: 'P78EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    max: 9999,
    min: 0,
    isFavorite: false,
  },
  {
    name: 'P79',
    idx: 'P79',
    translationKey: 'P79EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    max: 9999,
    min: 0,
    isFavorite: false,
  },
  {
    name: 'P80',
    idx: 'P80',
    translationKey: 'P80EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    hideMinMax: true,
    max: 1,
    min: 0,
    isFavorite: false,
    selectValues: [
      capitalize(ls.acidificationDechlorinationReduction),
      capitalize(ls.alkalizationChlorinationOxidation),
    ],
  },
  {
    name: 'P81',
    idx: 'P81',
    translationKey: 'P81EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    hideMinMax: true,
    max: 1,
    min: 0,
    isFavorite: false,
    selectValues: [
      capitalize(ls.acidificationDechlorinationReduction),
      capitalize(ls.alkalizationChlorinationOxidation),
    ],
  },
  {
    name: 'P82',
    idx: 'P82',
    translationKey: 'P82EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    max: 9999,
    min: 0,
    isFavorite: false,
  },
  {
    name: 'P83',
    idx: 'P83',
    translationKey: 'P83EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    max: 9999,
    min: 0,
    isFavorite: false,
  },
  {
    name: 'P84',
    idx: 'P84',
    translationKey: 'P84EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    hideMinMax: true,
    max: 1,
    min: 0,
    isFavorite: false,
    selectValues: ['NA', 'NC'],
  },
  {
    name: 'P85',
    idx: 'P85',
    translationKey: 'P85EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    max: 9999,
    min: 0,
    isFavorite: false,
  },
  {
    name: 'P86',
    idx: 'P86',
    translationKey: 'P86EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    max: 9999,
    min: 0,
    isFavorite: false,
  },
  {
    name: 'P87',
    idx: 'P87',
    translationKey: 'P87EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    hideMinMax: true,
    max: 1,
    min: 0,
    isFavorite: false,
    selectValues: [
      capitalize(ls.acidificationDechlorinationReduction),
      capitalize(ls.alkalizationChlorinationOxidation),
    ],
  },
  {
    name: 'P88',
    idx: 'P88',
    translationKey: 'P88EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    hideMinMax: true,
    max: 1,
    min: 0,
    isFavorite: false,
    selectValues: [
      capitalize(ls.acidificationDechlorinationReduction),
      capitalize(ls.alkalizationChlorinationOxidation),
    ],
  },
  {
    name: 'P89',
    idx: 'P89',
    translationKey: 'P89EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    max: 9999,
    min: 0,
    isFavorite: false,
  },
  {
    name: 'P90',
    idx: 'P90',
    translationKey: 'P90EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    max: 9999,
    min: 0,
    isFavorite: false,
  },
  {
    name: 'P91',
    idx: 'P91',
    translationKey: 'P91EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    hideMinMax: true,
    max: 1,
    min: 0,
    isFavorite: false,
    selectValues: ['NA', 'NC'],
  },
  {
    name: 'P92',
    idx: 'P92',
    translationKey: 'P92EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    max: 9999,
    min: 0,
    isFavorite: false,
  },
  {
    name: 'P93',
    idx: 'P93',
    translationKey: 'P93EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    hideMinMax: true,
    max: 1,
    min: 0,
    isFavorite: false,
    selectValues: [
      capitalize(ls.acidificationDechlorinationReduction),
      capitalize(ls.alkalizationChlorinationOxidation),
    ],
  },
  {
    name: 'P94',
    idx: 'P94',
    translationKey: 'P94EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    max: 9999,
    min: 0,
    isFavorite: false,
  },
  {
    name: 'P95',
    idx: 'P95',
    translationKey: 'P95EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    max: 9999,
    min: 0,
    isFavorite: false,
  },
  {
    name: 'P96',
    idx: 'P96',
    translationKey: 'P96EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    max: 9999,
    min: 0,
    isFavorite: false,
  },
  {
    name: 'P97',
    idx: 'P97',
    translationKey: 'P97EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    hideMinMax: true,
    max: 1,
    min: 0,
    isFavorite: false,
    selectValues: [
      capitalize(ls.acidificationDechlorinationReduction),
      capitalize(ls.alkalizationChlorinationOxidation),
    ],
  },
  {
    name: 'P98',
    idx: 'P98',
    translationKey: 'P98EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    max: 9999,
    min: 0,
    isFavorite: false,
  },
  {
    name: 'P99',
    idx: 'P99',
    translationKey: 'P99EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    max: 9999,
    min: 0,
    isFavorite: false,
  },
  {
    name: 'P100',
    idx: 'P100',
    translationKey: 'P100EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    max: 9999,
    min: 0,
    isFavorite: false,
  },
  {
    name: 'P101',
    idx: 'P101',
    translationKey: 'P101EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    hideMinMax: true,
    max: 1,
    min: 0,
    isFavorite: false,
    selectValues: [
      capitalize(ls.acidificationDechlorinationReduction),
      capitalize(ls.alkalizationChlorinationOxidation),
    ],
  },
  {
    name: 'P102',
    idx: 'P102',
    translationKey: 'P102EW2ParamDesc',
    type: MachineType.Ew2,
    version: MachineVersion.Ew2_2CanaliTc,
    max: 9999,
    min: 0,
    isFavorite: false,
  },
];

const EF315PHClTcParameters = [
  ...EF315PHRxTcParameters.map((el) => ({
    ...el,
    version: MachineVersion.PHClTc,
  })),
];

const EF315PHCacTcParameters = [
  ...EF315PHRxTcParameters.map((el) => ({
    ...el,
    version: MachineVersion.PHCacTc,
  })),
];

const EW2PoolPHCacTcParameters = [
  ...EW2PoolPHOrpTcParameters.map((el) => ({
    ...el,
    version: MachineVersion.PHCacTc,
  })),
];
const EW2PoolPHCleTcParameters = [
  ...EW2PoolPHCacTcParameters.map((el) => ({
    ...el,
    version: MachineVersion.PHCleTc,
  })),
];

const Nova2PoolPHOrpTcParameters = [
  ...EW2PoolPHOrpTcParameters.filter((el) => !el.name.toLowerCase().includes('pwm')).map((el) => ({
    ...el,
    type: MachineType.Nova2Pool,
    version: MachineVersion.PHOrpTc,
  })),
];
const Nova2PoolPHCacTcParameters = [
  ...Nova2PoolPHOrpTcParameters.map((el) => ({
    ...el,
    type: MachineType.Nova2Pool,
    version: MachineVersion.PHCacTc,
  })),
];
const Nova2PoolPHCleTcParameters = [
  ...Nova2PoolPHCacTcParameters.map((el) => ({
    ...el,
    type: MachineType.Nova2Pool,
    version: MachineVersion.PHCleTc,
  })),
];

const allParameters: Parameter[] = cycleauFirstParameters
  .concat(cycleauProParameters)
  .concat(Mc14evoPHRxCle12TcParameters)
  .concat(Mc14evoPHRxCacTcParameters)
  .concat(Mc14evo_4CanaliTcParameters)
  .concat(Mco14_3SezCl2Parameters)
  .concat(Mco14_5SezCl2ClTClCParameters)
  .concat(NovaNsp161CMinParameters)
  .concat(NovaNsp161MaParameters)
  .concat(NovaNsp162PHRxParameters)
  .concat(NovaNsp163ACle11_12Parameters)
  .concat(NovaNsp163BCle16Parameters)
  .concat(EF315PHRxTcParameters)
  .concat(EF315PHClTcParameters)
  .concat(EF315PHCacTcParameters)
  .concat(EW2PoolPHOrpTcParameters)
  .concat(EW2PoolPHCacTcParameters)
  .concat(EW2PoolPHCleTcParameters)
  .concat(Nova2PoolPHOrpTcParameters)
  .concat(Nova2PoolPHCacTcParameters)
  .concat(Nova2PoolPHCleTcParameters)
  .concat(EW22CanaliTCParameters);

const parseAllParametersDesc = (parameters: Parameter[], module: Maybe<Module>): Parameter[] => {
  // parse also param values ​​that depend on other values
  if (!module) {
    return parameters;
  }
  const moduleParameters = module?.Parameter?.parameters as ParametersType;
  const moduleSettings = module?.ModuleSettings?.settings as ModuleSettingsType;
  const output = clone(parameters);
  switch (module.type) {
    case MachineType.Mc14Evo:
    case MachineType.Mco14Evo: {
      const p03Idx = output.findIndex((el) => el.idx === 'P03');
      const p04Idx = output.findIndex((el) => el.idx === 'P04');
      const p11Idx = output.findIndex((el) => el.idx === 'P11');
      const p12Idx = output.findIndex((el) => el.idx === 'P12');
      const p27Idx = output.findIndex((el) => el.idx === 'P27');
      const p28Idx = output.findIndex((el) => el.idx === 'P28');
      if (isNotNullAndNotUndefined(moduleParameters?.P02) && p03Idx >= 0 && p04Idx >= 0) {
        if (
          (moduleParameters!.P02! >= 1 && moduleParameters!.P02! <= 4) ||
          (moduleParameters!.P02! >= 7 && moduleParameters!.P02! <= 8)
        ) {
          output[p03Idx].isFavorite = true;
          output[p04Idx].isFavorite = true;
          output[p03Idx].translationKey = 'P03MC14ParamDescOption1';
          output[p04Idx].translationKey = 'P04MC14ParamDescOption1';
        } else if (moduleParameters!.P02! === 5 || moduleParameters!.P02! === 6) {
          output[p03Idx].translationKey = 'P03MC14ParamDescOption2';
          output[p04Idx].translationKey = 'P04MC14ParamDescOption2';
        } else if (moduleParameters!.P02! === 9) {
          output[p03Idx].translationKey = 'P03MC14ParamDescOption3';
          output[p04Idx].translationKey = 'P04MC14ParamDescOption3';
        }
      }
      if (isNotNullAndNotUndefined(moduleParameters?.P10) && p11Idx >= 0 && p12Idx >= 0) {
        if (
          (moduleParameters!.P10! >= 1 && moduleParameters!.P10! <= 4) ||
          (moduleParameters!.P10! >= 7 && moduleParameters!.P10! <= 8)
        ) {
          output[p11Idx].isFavorite = true;
          output[p12Idx].isFavorite = true;
          output[p11Idx].translationKey = 'P11MC14ParamDescOption1';
          output[p12Idx].translationKey = 'P12MC14ParamDescOption1';
        } else if (moduleParameters!.P10! === 5 || moduleParameters!.P10! === 6) {
          output[p11Idx].translationKey = 'P11MC14ParamDescOption2';
          output[p12Idx].translationKey = 'P12MC14ParamDescOption2';
        } else if (moduleParameters!.P10! === 9) {
          output[p11Idx].translationKey = 'P11MC14ParamDescOption3';
          output[p12Idx].translationKey = 'P12MC14ParamDescOption3';
        }
      }
      if (isNotNullAndNotUndefined(moduleParameters?.P26) && p27Idx >= 0 && p28Idx >= 0) {
        if (
          (moduleParameters!.P26! >= 1 && moduleParameters!.P26! <= 4) ||
          (moduleParameters!.P26! >= 7 && moduleParameters!.P26! <= 8)
        ) {
          output[p27Idx].isFavorite = true;
          output[p28Idx].isFavorite = true;
          output[p27Idx].translationKey = 'P27MC14ParamDescOption1';
          output[p28Idx].translationKey = 'P28MC14ParamDescOption1';
        } else if (moduleParameters!.P26! === 5 || moduleParameters!.P26! === 6) {
          output[p27Idx].translationKey = 'P27MC14ParamDescOption2';
          output[p28Idx].translationKey = 'P28MC14ParamDescOption2';
        } else if (moduleParameters!.P26! === 9) {
          output[p27Idx].translationKey = 'P27MC14ParamDescOption3';
          output[p28Idx].translationKey = 'P28MC14ParamDescOption3';
        }
      }
      break;
    }
    case MachineType.Ew2Pool:
    case MachineType.Nova2Pool: {
      const p04Idx = output.findIndex((el) => el.idx === 'P04');
      const p05Idx = output.findIndex((el) => el.idx === 'P05');
      const p06Idx = output.findIndex((el) => el.idx === 'P06');
      const p07Idx = output.findIndex((el) => el.idx === 'P07');
      const p08Idx = output.findIndex((el) => el.idx === 'P08');
      const p09Idx = output.findIndex((el) => el.idx === 'P09');
      const p10Idx = output.findIndex((el) => el.idx === 'P10');
      const p11Idx = output.findIndex((el) => el.idx === 'P11');
      const p12Idx = output.findIndex((el) => el.idx === 'P12');
      const p13Idx = output.findIndex((el) => el.idx === 'P13');
      const p14Idx = output.findIndex((el) => el.idx === 'P14');
      const p15Idx = output.findIndex((el) => el.idx === 'P15');
      const p25Idx = output.findIndex((el) => el.idx === 'P25');
      const p26Idx = output.findIndex((el) => el.idx === 'P26');
      const p27Idx = output.findIndex((el) => el.idx === 'P27');
      if (p04Idx >= 0) {
        output[p04Idx].max = moduleParameters?.maxFreqM1 ?? 65535;
      }
      if (p05Idx >= 0) {
        output[p05Idx].min = moduleParameters?.minFreqM1 ?? 0;
      }
      if (p06Idx >= 0) {
        output[p06Idx].max = moduleParameters?.maxLimitM1 ?? 65535;
      }
      if (p07Idx >= 0) {
        output[p07Idx].min = moduleParameters?.minLimitM1 ?? 0;
      }
      if (p11Idx >= 0) {
        output[p11Idx].max = moduleParameters?.maxFreqM2 ?? 65535;
      }
      if (p12Idx >= 0) {
        output[p12Idx].min = moduleParameters?.minFreqM2 ?? 0;
      }
      if (p13Idx >= 0) {
        output[p13Idx].max = moduleParameters?.maxLimitM2 ?? 65535;
      }
      if (p14Idx >= 0) {
        output[p14Idx].min = moduleParameters?.minLimitM2 ?? 0;
      }
      if (p15Idx >= 0) {
        output[p15Idx].min = moduleParameters?.minLimitM2 ?? 0;
        output[p15Idx].max = moduleParameters?.maxLimitM2 ?? 0;
      }
      if (
        module.modelVersion === MachineVersion.PHCacTc ||
        module.modelVersion === MachineVersion.PHCleTc
      ) {
        if (p08Idx >= 0) {
          output[p08Idx].min = 0;
          output[p08Idx].max = 10;
        }
        if (p09Idx >= 0) {
          output[p09Idx].min = 0;
          output[p09Idx].max = 10;
        }
        if (p10Idx >= 0) {
          output[p10Idx].min = 0;
          output[p10Idx].max = 10;
        }
        if (p14Idx >= 0) {
          output[p14Idx].max = 10;
        }
        if (p25Idx >= 0) {
          output[p25Idx].min = 0;
          output[p25Idx].max = 10;
        }
        if (p26Idx >= 0) {
          output[p26Idx].min = 0.05;
          output[p26Idx].max = 10;
        }
        if (p27Idx >= 0) {
          output[p27Idx].min = 0.05;
          output[p27Idx].max = 10;
        }
      }
      break;
    }
    case MachineType.Ew2: {
      const getMeasurementFromName = (parameterName: string): 'm1' | 'm2' => {
        if (parameterName.includes('M1')) {
          return 'm1';
        }
        if (parameterName.includes('M2')) {
          return 'm2';
        }
        const pNum = Number(parameterName.replace('P', '').replace('a', '').replace('b', ''));
        if (pNum <= 26 || (pNum >= 53 && pNum <= 77)) {
          return 'm1';
        } else {
          return 'm2';
        }
      };
      for (let i = 0; i < output.length; i++) {
        if (output[i].selectValues) {
          continue;
        }
        const m = getMeasurementFromName(output[i].name);
        if (m === 'm1') {
          output[i].min = moduleSettings?.[0]?.scaleStart ?? 0;
          output[i].max = moduleSettings?.[0]?.scaleEnd ?? 65535;
        } else if (m === 'm2') {
          output[i].min = moduleSettings?.[1]?.scaleStart ?? 0;
          output[i].max = moduleSettings?.[1]?.scaleEnd ?? 65535;
        }
      }
      const p04Idx = output.findIndex((el) => el.idx === 'P04');
      const p05Idx = output.findIndex((el) => el.idx === 'P05');
      const p09Idx = output.findIndex((el) => el.idx === 'P09');
      const p10Idx = output.findIndex((el) => el.idx === 'P10');
      const p13Idx = output.findIndex((el) => el.idx === 'P13');
      const p14Idx = output.findIndex((el) => el.idx === 'P14');
      const p17Idx = output.findIndex((el) => el.idx === 'P17');
      const p18Idx = output.findIndex((el) => el.idx === 'P18');
      const p22Idx = output.findIndex((el) => el.idx === 'P22'); // minLimitM1
      const p23Idx = output.findIndex((el) => el.idx === 'P23'); // maxLimitM1
      const p30Idx = output.findIndex((el) => el.idx === 'P30');
      const p31Idx = output.findIndex((el) => el.idx === 'P31');
      const p35Idx = output.findIndex((el) => el.idx === 'P35');
      const p36Idx = output.findIndex((el) => el.idx === 'P36');
      const p39Idx = output.findIndex((el) => el.idx === 'P39');
      const p40Idx = output.findIndex((el) => el.idx === 'P40');
      const p43Idx = output.findIndex((el) => el.idx === 'P43');
      const p44Idx = output.findIndex((el) => el.idx === 'P44');
      const p48Idx = output.findIndex((el) => el.idx === 'P48'); // minLimitM2
      const p49Idx = output.findIndex((el) => el.idx === 'P49'); // maxLimitM2
      const p50Idx = output.findIndex((el) => el.idx === 'P50');
      const p51Idx = output.findIndex((el) => el.idx === 'P51');
      const p52Idx = output.findIndex((el) => el.idx === 'P52');
      const p53Idx = output.findIndex((el) => el.idx === 'P53');
      const p54Idx = output.findIndex((el) => el.idx === 'P54');
      const p57Idx = output.findIndex((el) => el.idx === 'P57');
      const p58Idx = output.findIndex((el) => el.idx === 'P58');
      const p60Idx = output.findIndex((el) => el.idx === 'P60');
      const p61Idx = output.findIndex((el) => el.idx === 'P61');
      const p64Idx = output.findIndex((el) => el.idx === 'P64');
      const p65Idx = output.findIndex((el) => el.idx === 'P65');
      const p67Idx = output.findIndex((el) => el.idx === 'P67');
      const p69Idx = output.findIndex((el) => el.idx === 'P69');
      const p70Idx = output.findIndex((el) => el.idx === 'P70');
      const p72Idx = output.findIndex((el) => el.idx === 'P72');
      const p75Idx = output.findIndex((el) => el.idx === 'P75');
      const p77Idx = output.findIndex((el) => el.idx === 'P77');
      const p78Idx = output.findIndex((el) => el.idx === 'P78');
      const p79Idx = output.findIndex((el) => el.idx === 'P79');
      const p82Idx = output.findIndex((el) => el.idx === 'P82');
      const p83Idx = output.findIndex((el) => el.idx === 'P83');
      const p85Idx = output.findIndex((el) => el.idx === 'P85');
      const p86Idx = output.findIndex((el) => el.idx === 'P86');
      const p89Idx = output.findIndex((el) => el.idx === 'P89');
      const p90Idx = output.findIndex((el) => el.idx === 'P90');
      const p92Idx = output.findIndex((el) => el.idx === 'P92');
      const p94Idx = output.findIndex((el) => el.idx === 'P94');
      const p95Idx = output.findIndex((el) => el.idx === 'P95');
      const p97Idx = output.findIndex((el) => el.idx === 'P97');
      const p98Idx = output.findIndex((el) => el.idx === 'P98');
      const p102Idx = output.findIndex((el) => el.idx === 'P102');
      if (p04Idx >= 0) {
        output[p04Idx].max = moduleParameters?.P05 ?? 65535;
      }
      if (p05Idx >= 0) {
        output[p05Idx].min = moduleParameters?.P04 ?? 0;
      }
      if (p09Idx >= 0) {
        output[p09Idx].max = moduleParameters?.P10 ?? 65535;
      }
      if (p10Idx >= 0) {
        output[p10Idx].min = moduleParameters?.P09 ?? 0;
      }
      if (p13Idx >= 0) {
        output[p13Idx].min = 0;
        output[p13Idx].max = moduleParameters?.P14 ?? 65535;
      }
      if (p14Idx >= 0) {
        output[p14Idx].min = moduleParameters?.P13 ?? 0;
        output[p14Idx].max = 180;
      }
      if (p17Idx >= 0) {
        output[p17Idx].min = 0;
        output[p17Idx].max = moduleParameters?.P18 ?? 65535;
      }
      if (p18Idx >= 0) {
        output[p18Idx].min = moduleParameters?.P17 ?? 0;
        output[p18Idx].max = 180;
      }
      if (p22Idx >= 0) {
        output[p22Idx].max = moduleParameters?.maxLimitM1 ?? 65535;
      }
      if (p23Idx >= 0) {
        output[p23Idx].min = moduleParameters?.minLimitM1 ?? 0;
      }
      if (p30Idx >= 0) {
        output[p30Idx].max = moduleParameters?.P31 ?? 65535;
      }
      if (p31Idx >= 0) {
        output[p31Idx].min = moduleParameters?.P30 ?? 0;
      }
      if (p35Idx >= 0) {
        output[p35Idx].min = moduleParameters?.P36 ?? 0;
      }
      if (p36Idx >= 0) {
        output[p36Idx].min = moduleParameters?.P35 ?? 0;
      }
      if (p39Idx >= 0) {
        output[p39Idx].min = 0;
        output[p39Idx].max = moduleParameters?.P40 ?? 65535;
      }
      if (p40Idx >= 0) {
        output[p40Idx].min = moduleParameters?.P39 ?? 0;
        output[p40Idx].max = 180;
      }
      if (p43Idx >= 0) {
        output[p43Idx].min = 0;
        output[p43Idx].max = moduleParameters?.P44 ?? 65535;
      }
      if (p44Idx >= 0) {
        output[p44Idx].min = moduleParameters?.P43 ?? 0;
        output[p44Idx].max = 180;
      }
      if (p48Idx >= 0) {
        output[p48Idx].max = moduleParameters?.maxLimitM2 ?? 65535;
      }
      if (p49Idx >= 0) {
        output[p49Idx].min = moduleParameters?.minLimitM2 ?? 0;
      }
      if (p50Idx >= 0) {
        output[p50Idx].min = moduleParameters?.minLimitM2 ?? 0;
        output[p50Idx].max = moduleParameters?.maxLimitM2 ?? 65535;
      }
      if (p51Idx >= 0) {
        output[p51Idx].min = moduleParameters?.minLimitM2 ?? 0;
        output[p51Idx].max = moduleParameters?.maxLimitM2 ?? 65535;
      }
      if (p52Idx >= 0) {
        output[p52Idx].min = moduleParameters?.minLimitM2 ?? 0;
        output[p52Idx].max = moduleParameters?.maxLimitM2 ?? 65535;
      }
      if (p53Idx >= 0) {
        output[p53Idx].min = 0;
      }
      if (p54Idx >= 0) {
        output[p54Idx].min = 0;
      }
      if (p57Idx >= 0) {
        output[p57Idx].min = 0;
        output[p57Idx].max = 480;
      }
      if (p58Idx >= 0) {
        output[p58Idx].min = 0;
        output[p58Idx].max = 480;
      }
      if (p60Idx >= 0) {
        output[p60Idx].min = 0;
      }
      if (p61Idx >= 0) {
        output[p61Idx].min = 0;
      }
      if (p64Idx >= 0) {
        output[p64Idx].min = 0;
        output[p64Idx].max = 480;
      }
      if (p65Idx >= 0) {
        output[p65Idx].min = 0;
        output[p65Idx].max = 480;
      }
      if (p67Idx >= 0) {
        output[p67Idx].min = 0;
      }
      if (p69Idx >= 0) {
        output[p69Idx].min = 0;
        output[p69Idx].max = 480;
      }
      if (p70Idx >= 0) {
        output[p70Idx].min = 0;
      }
      if (p72Idx >= 0) {
        output[p72Idx].min = 0;
        output[p72Idx].max = 480;
      }
      if (p75Idx >= 0) {
        output[p75Idx].min = 0;
      }
      if (p77Idx >= 0) {
        output[p77Idx].min = 0;
        output[p77Idx].max = 480;
      }
      if (p78Idx >= 0) {
        output[p78Idx].min = 0;
      }
      if (p79Idx >= 0) {
        output[p79Idx].min = 0;
      }
      if (p82Idx >= 0) {
        output[p82Idx].min = 0;
        output[p82Idx].max = 480;
      }
      if (p83Idx >= 0) {
        output[p83Idx].min = 0;
        output[p83Idx].max = 480;
      }
      if (p85Idx >= 0) {
        output[p85Idx].min = 0;
      }
      if (p86Idx >= 0) {
        output[p86Idx].min = 0;
      }
      if (p89Idx >= 0) {
        output[p89Idx].min = 0;
        output[p89Idx].max = 480;
      }
      if (p90Idx >= 0) {
        output[p90Idx].min = 0;
        output[p90Idx].max = 480;
      }
      if (p92Idx >= 0) {
        output[p92Idx].min = 0;
      }
      if (p94Idx >= 0) {
        output[p94Idx].min = 0;
        output[p94Idx].max = 480;
      }
      if (p95Idx >= 0) {
        output[p95Idx].min = 0;
      }
      if (p97Idx >= 0) {
        output[p97Idx].min = 0;
        output[p97Idx].max = 480;
      }
      if (p98Idx >= 0) {
        output[p98Idx].min = 0;
      }
      if (p102Idx >= 0) {
        output[p102Idx].min = 0;
        output[p102Idx].max = 480;
      }
      break;
    }

    default:
      break;
  }
  return output;
};

export { allParameters, parseAllParametersDesc };
