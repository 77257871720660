export const es = {
  no: 'No',
  yes: 'Sí',
  userNotFound: 'Usuario no encontrado',
  invalidCredentials: 'Credenciales no válidas',
  badOldPassword: 'Contraseña antigua incorrecta',
  somethingWentWrong: 'Algo salió mal',
  sww: 'Algo salió mal',
  tooReqError: 'Demasiadas solicitudes, vuelva a intentar más tarde',
  fieldRequired: 'Campo requerido',
  invalidEmail: 'Email inválido',
  passwordTooShort: 'La contraseña debe contener al menos {0} caracteres',
  login: 'Acceso',
  signIn: 'Acceder',
  email: 'Email',
  password: 'Contraseña',
  dashboard: 'Panel de control',
  users: 'Usuarios',
  customers: 'Clientes',
  managers: 'Gerentes',
  totalDevices: 'Instalaciones totales',
  onlineDevices: 'Instalaciones en línea',
  devicesOnAlarm: 'Instalaciones en alarma',
  deviceStatus: 'Etado de las instalaciones',
  search: 'Buscar',
  mac: 'MAC',
  model: 'Modelo',
  registrationNumber: 'Número de serie',
  alarms: 'Alarmas',
  warnings: 'Advertencias',
  status: 'Estado',
  lastConnection: 'Última conexión',
  details: 'Detalles',
  summary: 'Resumen',
  viewAll: 'Ver todo',
  customersList: 'Lista clientes',
  managerList: 'Lista gerentes',
  id: 'ID',
  nameSurname: 'Nombre y apellido',
  city: 'Ciudad',
  role: 'Role',
  notAuthorized: 'No autorizado',
  confirm: 'Confirmar',
  disconnectQuestion: '¿Cerrar sesión?',
  logout: 'Cerrar sesión',
  menu: 'Menú',
  online: 'en línea',
  offline: 'desconectado',
  actives: 'Activos',
  user: 'Usuario',
  admin: 'Admministrador',
  technician: 'Técnico',
  alarmsHistory: 'Historial de alarmas',
  date: 'Fecha',
  dates: 'Fechas',
  name: 'nombre',
  type: 'tipo',
  alarm: 'Alarma',
  warning: 'Advertencia',
  forgetPasswordQuestion: '¿Has olvidado tu contraseña?',
  signUp: 'Iniciar sesión',
  registration: 'Registrarse',
  attention: 'Atención',
  ok: 'ok',
  acceptDataPrivacyAgreementWarn: 'Debes consentir el tratamiento de datos',
  acceptDataMarketingAgreementWarn:
    'Seleccione una opción par el uso de todos los datos con fines de marketing',
  passwordMismatch: 'Las contraseñas no coinciden',
  repeatPassword: 'Vuelva a escribir la contraseña',
  telephone: 'Teléfono',
  nation: 'País',
  company: 'Plataforma',
  dataAgreement:
    'Al marcar la casilla, accepta las condiciones para el tratamiento de datos personales según la directiva EU 679/2016 y declaras que eres mayor de edad',
  agree: 'Acepto',
  notAgree: 'No acepto',
  marketingAgreement:
    'Al marcar la casilla, accepta que STEIEL utilice sus datos personales con fines de marketing',
  privacyTreatment: 'Tratamiento de datos personales',
  verifyEmail: 'Verificación de email',
  emailSentToYourEmail: 'Hemos enviado un correo electrónico de verificación a:',
  emailNotReceivedQuestion: '¿No recibiste el correo electrónico?',
  sendAgain: 'Reenviar',
  verificationEmailSent: 'Email de verificación enviada',
  lostPasswordQuestion: '¿Has olvidado tu contraseña?',
  lostPasswordInstructions:
    'Ingrese tu dirección de correo electrónico y te enviaremos instrucciones para configurar una nueva contraseña',
  resetPassword: 'Restablecer la contraseña',
  goBack: 'Regresar',
  emailSentToYourAddress: 'Hemos enviado un correo electrónico a tu dirección',
  passwordChanged: 'Contraseña cambiada',
  notice: 'Advertencia',
  changePassword: 'Editar contraseña',
  settings: 'Ajustes',
  add: 'Agregar',
  profile: 'Perfil',
  security: 'Seguridad',
  saveChanges: 'Guardar cambios',
  profileSavedSuccessfully: 'Perfil guardado con éxito',
  dataSavedSuccessfully: 'Datos guardados con éxito',
  oldPassword: 'Contraseña antigua',
  passwordChangedSuccessfully: 'Contraseña cambiada con éxito',
  counterResettedSuccessfully: 'Contador reiniciado con éxito',
  at: 'a',
  noDataFound: 'Ningún dato encontrado',
  deviceManagement: 'Administrador de instalaciones',
  usersManagement: 'Administrador de usuarios',
  version: 'Versión',
  dateAndTime: 'Fecha/Hora',
  place: 'Lugar',
  comeback: 'Regresar',
  setup: 'Configuración',
  default: 'Default',
  interface: 'Interfaz',
  temperature: 'Temperatura',
  ph: 'pH',
  chlorine: 'Cloro',
  totalChlorine: 'Cloro total',
  freeChlorine: 'Cloro libre',
  combinedChlorine: 'Cloro combinado',
  table: 'Tabla',
  charts: 'Gráficos',
  setpoint: 'Setpoint',
  customizeMeasurements: 'Personalizar layout',
  active: 'Activo',
  deactivated: 'No activo',
  minAcceptableValue: 'Límite mín aceptable',
  maxAcceptableValue: 'Límite máx aceptable',
  outputs: 'Salidas',
  inputs: 'Entradas',
  on: 'On',
  off: 'Off',
  value: 'Valor',
  exportTable: 'Exportar tabla',
  exportAlarms: 'Exportar alarmas',
  localDateTime: 'Fecha/Hora (local)',
  notes: 'Notas',
  channel: 'Canal',
  description: 'Descripción',
  deviceDetails: 'Detalles instalación',
  measure: 'Medición',
  enable: 'Habilitar',
  unitOfMeasure: 'Unidad de medida',
  scaleStart: 'Inicio de escala',
  bottomScale: 'Fin de escala',
  minAlarm: 'Alarma mín',
  maxAlarm: 'Alarma máx',
  minAcceptableLimit: 'Límite mín aceptable',
  maxAcceptableLimit: 'Límite máx aceptable',
  decimals: 'Decimales',
  selectWidget: 'Seleccionar Widget',
  notifications: 'Notificaciones',
  notificationsDelay: 'Retraso de notificación (minutos)',
  recipientList: 'Lista de recipientes',
  customizeNotification: 'Personalizar notificación',
  apply: 'Aplicar',
  view: 'Ver',
  searchPlaceholder: 'Buscar ID, nombre, lugar',
  filter: 'Filtro',
  withAlarm: 'En alarma',
  notWithAlarm: 'No en alarma',
  cancel: 'Cancelar',
  deviceDefaultSettingsResetQuestion:
    '¿Desea restablecer la instalación a la configuración predeterminada?',
  samplingTime: 'Tiempo de muestreo (min)',
  timeZone: 'Descompensación horaria',
  macAddress: 'Dirección MAC',
  tool: 'Instrumento',
  newCustomer: 'Nuevo usuario',
  newManager: 'Nuevo gerente',
  locality: 'Ubicación',
  notificationManagement: 'Gestión de notificaciones',
  manageNotifications: 'Administra notificaciones',
  sendEmail: 'Envia email',
  usersList: 'Lista de usuarios',
  modifyCustomer: 'Editar usuario',
  modifyManager: 'Editar gerente',
  newUser: 'Nuevo usuario',
  deleteUser: 'Cancelar usuario',
  notePlaceholder: 'Insertar nota',
  insertLogo: 'Insertar lugar',
  language: 'Idioma',
  file: 'Archivo',
  create: 'Crear',
  modify: 'Editar',
  update: 'Actualizar',
  createNewCustomer: 'Crear nuevo usuario',
  createNewManager: 'Crear nuevo gerente',
  viewer: 'Visualizador',
  superTechnician: 'Instalador',
  supertechnician: 'Instalador',
  newDevice: 'Nueva instalación',
  createNewDevice: 'Crear nueva instalación',
  installer: 'Instalador',
  newModule: 'Nuevo modulo',
  modifyDevice: 'Editar instalación',
  searchByNamePlace: 'Buscar nombre, ubicación',
  searchByNameSurnameUsername: 'Buscar nombre, apellido, nombre de usuario...',
  surname: 'Apellido',
  username: 'Nombre de usuario',
  modifyUser: 'Editar usuario',
  confirmPassword: 'Confirmar contraseña',
  insertNote: 'Insertar nota',
  createNewUser: 'Crear nuevo utente',
  customersManagement: 'Administrador de usuarios',
  customer: 'Usuario',
  manager: 'Gerente',
  send: 'Enviar',
  modifyPassword: 'Editar contraseña',
  newPassword: 'Nueva contraseña',
  passwordRecoveryInstructions:
    'Ingrese tu correo electrónico, te enviaremos un enlace para cambiar tu contraseña',
  userProfile: 'Perfil de usuario',
  italian: 'Italiano',
  english: 'Inglés',
  currentPassword: 'Contraseña actual',
  new: 'Nuevo',
  deviceInterfaceChartTitle: 'SwimEx Cold Plunge Pool',
  deviceInterfaceChartMobileTip:
    'Oriente el dispositivo horizontalmente para una mejor vista del gráfico',
  deviceInterfaceChartTip: 'Coloque el puntero sobre el gráfico para ver los datos',
  dtBodyNoMatch: 'Ningún dato encontrado',
  dtBodyTooltip: 'Ordenar',
  dtBodyColumnHeaderTooltip: 'Ordenar por',
  dtNextPage: 'Página siguiente',
  dtPreviousPage: 'Página anterior',
  dtRowsPerPage: 'Líneas por página:',
  jumpToPage: 'Ir a la página:',
  dtDisplayRows: 'de',
  dtToolbarSearch: 'Buscar',
  dtToolbarDownloadCsv: 'Descargar CSV',
  dtToolbarPrint: 'Imprimir',
  dtToolbarViewColumns: 'Vista de columnas',
  dtToolbarFilterTable: 'Filtrar tabla',
  dtFilterAll: 'Todo',
  dtFilterTitle: 'Filtros',
  dtFilterReset: 'Reiniciar',
  dtViewColumnsTitle: 'Mostrar columnas',
  dtViewColumnsTitleAria: 'Mostrar/ocultar columnas',
  dtSelectedRowsText: 'Líneas seleccionadas',
  dtSelectedRowsDelete: 'Cancelar',
  dtSelectedRowsDeleteAria: 'Cancelar líneas seleccionadas',
  success: 'Exitoso',
  recoveryPasswordEmailSent: 'Email para recuperación de contraseña enviado',
  french: 'Francés',
  noInterfaceConnected: 'Ninguna interfaz conectada',
  hthInterfaceName: 'CYCL’EAU First',
  hthProInterfaceName: 'CYCL’EAU Pro',
  input_pHLvl: 'Nivel pH',
  input_cl2Lvl: 'Nivel cloro',
  input_lvl3: 'Nivel 3',
  input_flow: 'Flow',
  input_cadOff: 'CAD (Off)',
  output_out1: 'Out 1',
  output_out2: 'Out 2',
  output_alarm: 'Relé alarma',
  alarm_lowPHLvl: 'Nivel pH bajo',
  alarm_lowCl2Lvl: 'Nivel cloro bajo',
  alarm_flow: 'Flow',
  alarm_onOffKey: 'Botón ON/OFF',
  alarm_inputCadOff: 'Entrada CAD Off',
  alarm_maxPHDosage: 'Máx dosificación pH',
  alarm_maxCl2Dosage: 'Máx dosificación cloro',
  alarm_startDelay: 'Retraso de puesta en marcha',
  alarm_pHMin: 'pH mínimo',
  alarm_phMax: 'pH máximo',
  alarm_cl2Min: 'Cl mínimo',
  alarm_cl2Max: 'Cl máximo',
  noMeasurementFound: 'Ninguna medición encontrada',
  settingsSavedSuccessfully: 'Configuración guardada con éxito',
  parameters: 'Parámetros',
  parameter: 'Parámetro',
  noParametersFound: 'Ningún parámetro encontrado',
  setpointPHParamDesc: 'Setpoint 1 (OUT_pH)',
  setpointCl2ParamDesc: 'Setpoint 2 (OUT_Cl)',
  minPHAlarmParamDesc: 'Alarma (advertencia) mín. pH',
  maxPHAlarmParamDesc: 'Alarma (advertencia) máx. pH',
  minFreeChlorineParamDesc: 'Alarma (advertencia) mín. cloro',
  maxFreeChlorineParamDesc: 'Alarma (advertencia) máx. cloro',
  maxCombChlorineParamDesc: 'Alarma (advertencia) máx. cloro combinado',
  settingTypePHParamDesc: 'Tipo de ajuste OUT_pH',
  settingTypeCl2ParamDesc: 'Tipo de ajuste OUT_Cl',
  hysteresisPHParamDesc: 'Histéresis OUT_pH',
  hysteresisCl2ParamDesc: 'Histéresis OUT_Cl',
  sensePHParamDesc: 'Dirección pH',
  startDelayParamDesc: 'Retraso a la puesta en marcha (segundos)',
  flowDelayParamDesc: 'Retraso después de restaurar el flujo (segundos)',
  maxInjectionTimeParamDesc: 'Tiempo de inyección máx (min)',
  pHTankLvlParamDesc: 'Nivel tanque pH',
  chlorineTankLvlParamDesc: 'Nivel tanque cloro',
  flowSensorParamDesc: 'Sensor de caudal',
  totalChlorineParamDesc: 'Cloro total',
  languageParamDesc: 'Idioma',
  alarmRelayParamDesc: 'Relé de alarma',
  serialLineParamDesc: 'Línea serial',
  serialLineCommSpeedParamDesc: 'Velocidad de comunicación de la línea serial',
  modbusCommAddrParamDesc: 'Dirección de comunicación Modbus',
  passwordParamDesc: 'Contraseña',
  proportional: 'Proporcional',
  onOff: 'On-Off',
  alkaline: 'Alcalino',
  acid: 'Ácido',
  spanish: 'Español',
  ascii: 'Ascii',
  modbus: 'Modbus',
  min: 'mín',
  max: 'máx',
  onlineWithAlarm: 'En línea con alarma',
  macAddressNotFound: 'Dirección MAC no encontrado',
  invalidCompanyValue: 'Valor plataforma no válido',
  deleteDevice: 'Eliminar instalación',
  deleteDeviceQuestion: 'Cancelar la instalación?',
  userCreatedSuccessfully: 'Usuario creado con éxito',
  deleteUserQuestion: 'Cancelar el usuario {0}?',
  deviceCreatedSuccessfully: 'Instalación creada con éxito',
  noMeasurementsFoundOrEnabled: 'Ninguna medición habilitada o encontrada',
  companyDomain: 'Dominio de la plataforma',
  from: 'desde',
  to: 'a',
  alarmsFixed: 'Alarmas resueltas',
  badInputData: 'Datos de entrada incorrectos',
  changesCompleted: 'Ediciones completadas',
  upload: 'Cargar',
  invalidFileSize: 'Tamaño de archivo no válido',
  invalidFileType: 'Tipo de archivo no válido',
  alarm_maxTimeK1: 'Tiempo máximo K1',
  alarm_maxTimeK2: 'Tiempo máximo K2',
  alarm_maxTimeK4: 'Tiempo máximo K4',
  alarm_maxTimeK5: 'Tiempo máximo K5',
  alarm_ignitionDelay: 'Retraso puesta en marcha',
  alarm_pHStabilizer: 'Estabilización pH',
  alarm_clock: 'Reloj',
  alarm_zeroChlorine: 'Cero cloro',
  alarm_redox: 'Redox',
  alarm_superchlorination: 'Supercloración',
  alarm_notUsed1: 'No utilizado 1',
  alarm_notUsed2: 'No utilizado 2',
  alarm_notUsed3: 'No utilizado 3',
  alarm_notUsed4: 'No utilizado 4',
  alarm_richAssist: 'Solicitar asistencia',
  alarm_toolBlock: 'Bloque del instrumento',
  output_k1: 'k1',
  output_k2: 'k2',
  output_k3: 'k3',
  output_k4: 'k4',
  output_k5: 'k5',
  output_ev: 'ev',
  output_perist1: 'Perist1',
  output_perist2: 'Perist2',
  output_perist3: 'Perist3',
  output_mixer: 'Mixer 1',
  input_lvl1: 'Nivel 1',
  input_lvl2: 'Nivel 2',
  input_off: 'Entrada OFF',
  input_imp: 'Entrada IMP',
  P01MC14ParamDesc: 'Medición asociada a la sortida relé K1',
  P02MC14ParamDesc: 'Tipo sortida relé K1',
  P03MC14ParamDesc: '',
  P04MC14ParamDesc: '',
  P05MC14ParamDesc: '',
  P06MC14ParamDesc: '',
  P07MC14ParamDesc: 'Alarma máx. tiempo de dosificación relé K1 (h : min)',
  P08MC14ParamDesc: 'Condiciones de OFF relé K1',
  P09MC14ParamDesc: 'Medición asociada a la sortida relé K2',
  P10MC14ParamDesc: 'Tipo sortida relé K2',
  P11MC14ParamDesc: '',
  P12MC14ParamDesc: '',
  P13MC14ParamDesc: '',
  P14MC14ParamDesc: '',
  P15MC14ParamDesc: 'Alarma máx. tiempo de dosificación relé K2 (h : min)',
  P16MC14ParamDesc: 'Condiciones de OFF relé K2',
  P17MC14ParamDesc: '',
  P18MC14ParamDesc: '',
  P19MC14ParamDesc: '',
  P20MC14ParamDesc: '',
  P21MC14ParamDesc: '',
  P22MC14ParamDesc: '',
  P23MC14ParamDesc: '',
  P24MC14ParamDesc: '',
  P25MC14ParamDesc: '',
  P26MC14ParamDesc: '',
  P27MC14ParamDesc: '',
  P28MC14ParamDesc: '',
  P29MC14ParamDesc: '',
  P30MC14ParamDesc: '',
  P31MC14ParamDesc: '',
  P32MC14ParamDesc: '',
  P33MC14ParamDesc: '',
  P34MC14ParamDesc: '',
  P35MC14ParamDesc: '',
  P36MC14ParamDesc: '',
  P37MC14ParamDesc: '',
  P38MC14ParamDesc: '',
  P39MC14ParamDesc: '',
  P40MC14ParamDesc: '',
  P41MC14ParamDesc: '',
  P42MC14ParamDesc: '',
  P43MC14ParamDesc: '',
  P44MC14ParamDesc: '',
  P45MC14ParamDesc: '',
  P46MC14ParamDesc: '',
  P47MC14ParamDesc: '',
  P48MC14ParamDesc: '',
  P49MC14ParamDesc: '',
  P50MC14ParamDesc: '',
  P51MC14ParamDesc: '',
  P52MC14ParamDesc: '',
  P53MC14ParamDesc: '',
  P54MC14ParamDesc: '',
  P55MC14ParamDesc: '',
  P56MC14ParamDesc: '',
  P57MC14ParamDesc: '',
  P58MC14ParamDesc: '',
  P59MC14ParamDesc: '',
  P60MC14ParamDesc: '',
  P61MC14ParamDesc: '',
  P62MC14ParamDesc: '',
  P63MC14ParamDesc: '',
  P64MC14ParamDesc: '',
  P65MC14ParamDesc: '',
  P66MC14ParamDesc: '',
  P67MC14ParamDesc: '',
  P68MC14ParamDesc: '',
  P69MC14ParamDesc: '',
  P70MC14ParamDesc: '',
  P71MC14ParamDesc: '',
  P72MC14ParamDesc: '',
  P73MC14ParamDesc: '',
  P74MC14ParamDesc: '',
  P75MC14ParamDesc: '',
  P76MC14ParamDesc: '',
  P77MC14ParamDesc: '',
  P78MC14ParamDesc: '',
  P79MC14ParamDesc: '',
  P80MC14ParamDesc: '',
  P81MC14ParamDesc: '',
  P82MC14ParamDesc: '',
  P83MC14ParamDesc: '',
  P84MC14ParamDesc: '',
  P85MC14ParamDesc: '',
  P86MC14ParamDesc: '',
  P87MC14ParamDesc: '',
  P88MC14ParamDesc: '',
  P89MC14ParamDesc: '',
  P90MC14ParamDesc: '',
  P91MC14ParamDesc: '',
  P92MC14ParamDesc: '',
  P93MC14ParamDesc: '',
  P94MC14ParamDesc: '',
  notAvailable: 'No disponible',
  disabled: 'Desactivada',
  closingOverthresholds: 'Cierre al superar los umbrales',
  openingOverthreshold: 'Apertura al superar los umbrales',
  upward: 'Hacia harriba',
  downward: 'Hacia abajo',
  hourlyOperation: 'Operación por horas',
  unknown: 'Desconocido',
  mc14evoPHRxCle12TcInterfaceName: 'MC14 EVO pH/RX/CLE12 + T°C',
  mc14evoPHRxCacTcInterfaceName: 'MC14 EVO pH/RX/CAC + T°C',
  mc14evo_4CanaliTcInterfaceName: 'MC14 EVO 4 canales + T°C',
  mco14_3SezCl2InterfaceName: 'MCO14 EVO 3 secciones (Cl2)',
  mco14_5SezCl2ClTClCInterfaceName: 'MCO14 EVO 5 secciones (Cl2 + ClT + ClC)',
  nsp161InterfaceName: 'NOVA NSP161',
  nsp161CMinInterfaceName: 'NOVA NSP161 c/min',
  nsp161MAInterfaceName: 'NOVA NSP161 mA',
  nsp162PHRxInterfaceName: 'NOVA NSP162 pH/RX',
  nsp163ACle11_12InterfaceName: 'NOVA NSP163A CLE11/12',
  nsp163BCle16InterfaceName: 'NOVA NSP163B CLE16',
  pHRxTcInterfaceName: 'EF315 pH/RX/T°C',
  pHClTcInterfaceName: 'EF315 pH/CL/T°C',
  pHCacTcInterfaceName: 'EF315 pH/CAC/T°C',
  channels: 'Canales',
  sectionMin: 'Secciones',
  loggedInAs: 'Autenticado como',
  badRangeInput: 'Valores no válidos',
  open: 'Abrir',
  all: 'Todas',
  moduleComunicationTimeoutErrDesc: 'Comprobar el cableado y la alimentación de la interfaz',
  updateParamFailed: 'Actualización del parámetro fallida',
  saveChangesQuestion: '¿Está seguro de que desea guardar los cambios realizados?',
  secondaryParameters: 'Parámetros secundarios',
  P03MC14ParamDescOption1: 'Umbral a alcanzar relé K1',
  P03MC14ParamDescOption2: 'Valor central de alarma relé K1',
  P03MC14ParamDescOption3: 'Hora primera activación del relé K1',
  P04MC14ParamDescOption1: 'Histéresis relé K1',
  P04MC14ParamDescOption2: 'Histéresis por encima y por debajo del umbral relé K1',
  P04MC14ParamDescOption3: 'Hora primera desactivación del relé K1',
  P11MC14ParamDescOption1: 'Umbral a alcanzar relé K2',
  P11MC14ParamDescOption2: 'Valor central de alarma relé K2',
  P11MC14ParamDescOption3: 'Hora primera activación del relé K2',
  P12MC14ParamDescOption1: 'Histéresis relé K2',
  P12MC14ParamDescOption2: 'Histéresis por encima y por debajo del umbral relé K2',
  P12MC14ParamDescOption3: 'Hora primera desactivación del relé K2',
  P27MC14ParamDescOption1: 'Umbral a alcanzar relé K4',
  P27MC14ParamDescOption2: 'Valor central de alarma relé K4',
  P27MC14ParamDescOption3: 'Hora primera activación del relé K4',
  P28MC14ParamDescOption1: 'Histéresis relé K4',
  P28MC14ParamDescOption2: 'Histéresis por encima y por debajo del umbral relé K4',
  P28MC14ParamDescOption3: 'Hora primera desactivación del relé K4',
  alarm_lvl: 'Nivel',
  alarm_extcons: 'Consentimiento externo',
  alarm_stop: 'Stop',
  alarm_urOr: 'UR/OR',
  alarm_timer: 'Timer',
  alarm_maintenance: 'Mantenimiento',
  alarm_autoadesco: 'Auto-cebado',
  alarm_internalTemp: 'Temperatura interna',
  alarm_maxDoseTime: 'Máx tiempo dosificacón',
  alarm_maxAccPulses: 'Máx pulsos acumulables',
  alarm_sampleWater: 'Agua de muestra',
  alarm_clockKO: 'Reloj KO',
  output_magnetStatus: 'Estado imán',
  output_relayAlarm: 'Relé de status',
  input_lvl: 'Nivel',
  input_extcons: 'Consentimiento externo',
  P01NSPParamDesc: 'Idioma',
  P02NSPParamDesc: '',
  P03NSPParamDesc: '',
  P04NSPParamDesc: '',
  P05NSPParamDesc: '',
  P06NSPParamDesc: '',
  P07NSPParamDesc: 'Frecuencia máxima',
  P08NSPParamDesc: '',
  P09NSPParamDesc: '',
  P10NSPParamDesc: '',
  P11NSPParamDesc: 'Setpoint',
  P12NSPParamDesc: 'Histéresis / Banda proporcional',
  P13NSPParamDesc: '',
  P14NSPParamDesc: '',
  P15NSPParamDesc: '',
  P16NSPParamDesc: '',
  P17NSPParamDesc: '',
  P18NSPParamDesc: '',
  P19NSPParamDesc: '',
  P20NSPParamDesc: '',
  P21NSPParamDesc: '',
  P22NSPParamDesc: '',
  P23NSPParamDesc: '',
  P24NSPParamDesc: '',
  P25NSPParamDesc: '',
  P26NSPParamDesc: '',
  P27NSPParamDesc: '',
  P28NSPParamDesc: '',
  P29NSPParamDesc: '',
  P30NSPParamDesc: '',
  P31NSPParamDesc: '',
  P32NSPParamDesc: '',
  P33NSPParamDesc: '',
  P34NSPParamDesc: '',
  P35NSPParamDesc: '',
  P36NSPParamDesc: '',
  P37NSPParamDesc: '',
  P38NSPParamDesc: '',
  P39NSPParamDesc: '',
  P40NSPParamDesc: '',
  P41NSPParamDesc: '',
  P42NSPParamDesc: '',
  P43NSPParamDesc: '',
  P44NSPParamDesc: '',
  P45NSPParamDesc: '',
  P46NSPParamDesc: '',
  P47NSPParamDesc: '',
  P48NSPParamDesc: '',
  P49NSPParamDesc: '',
  P50NSPParamDesc: '',
  P51NSPParamDesc: '',
  P52NSPParamDesc: '',
  P53NSPParamDesc: '',
  P54NSPParamDesc: '',
  P55NSPParamDesc: '',
  P56NSPParamDesc: '',
  P57NSPParamDesc: '',
  P58NSPParamDesc: '',
  P59NSPParamDesc: '',
  P60NSPParamDesc: '',
  P61NSPParamDesc: '',
  P62NSPParamDesc: '',
  P63NSPParamDesc: '',
  P64NSPParamDesc: '',
  P65NSPParamDesc: '',
  P66NSPParamDesc: '',
  P67NSPParamDesc: '',
  P68NSPParamDesc: '',
  P69NSPParamDesc: '',
  P70NSPParamDesc: '',
  P71NSPParamDesc: '',
  P72NSPParamDesc: '',
  P73NSPParamDesc: '',
  P74NSPParamDesc: '',
  P75NSPParamDesc: '',
  P76NSPParamDesc: '',
  P77NSPParamDesc: '',
  P78NSPParamDesc: '',
  P79NSPParamDesc: '',
  P80NSPParamDesc: '',
  P81NSPParamDesc: '',
  P82NSPParamDesc: '',
  P83NSPParamDesc: '',
  P84NSPParamDesc: '',
  P85NSPParamDesc: '',
  P86NSPParamDesc: '',
  P87NSPParamDesc: '',
  P88NSPParamDesc: '',
  P89NSPParamDesc: '',
  P90NSPParamDesc: '',
  P91NSPParamDesc: '',
  P92NSPParamDesc: '',
  P93NSPParamDesc: '',
  P94NSPParamDesc: '',
  P95NSPParamDesc: '',
  alarm_lvl1: 'Nivel 1',
  alarm_lvl2: 'Nivel 2',
  alarm_maxDosageTimeOut1: 'Tiempo máx. dosificación Out 1',
  alarm_maxDosageTimeOut2: 'Tiempo máx. dosificación Out 2',
  alarm_urOrMeasure1: 'UR/OR Medida 1',
  alarm_urOrMeasure2: 'UR/OR Medida 2',
  alarm_maintenancePump1: 'Mantenimiento Bomba 1',
  alarm_maintenancePump2: 'Mantenimiento Bomba 2',
  alarm_recalibrateChlorineProbeAfter24h: 'Vuelva a calibrar la sonda de cloro después de 24 horas',
  alarm_halvedSetpointForBadCalib: 'Setpoint reducido a la mitad',
  input_stop: 'Stop',
  input_ignitionDelay: 'Retraso puesta en marcha',
  input_pHStabilizer: 'Estabilización pH',
  input_maintenancePump1: 'Mantenimiento Bomba 1',
  input_maintenancePump2: 'Mantenimiento Bomba 2',
  input_recalibrateChlorineProbeAfter24h: 'Vuelva a calibrar la sonda de cloro después de 24 horas',
  input_halvedSetpointForBadCalib: 'Setpoint reducido a la mitad',
  P01EFParamDesc: '',
  P02EFParamDesc: '',
  P03EFParamDesc: 'Setpoint (Out 1)',
  P04EFParamDesc: 'Histéresis / Banda proporcional (Out 1)',
  P05EFParamDesc: 'Frecuencia máxima (Out 1)',
  P06EFParamDesc: '',
  P07EFParamDesc: '',
  P08EFParamDesc: '',
  P09EFParamDesc: '',
  P10EFParamDesc: 'Setpoint (Out 2)',
  P11EFParamDesc: 'Histéresis / Banda proporcional (Out 2)',
  P12EFParamDesc: 'Frecuencia máxima (Out 2)',
  P13EFParamDesc: '',
  P14EFParamDesc: '',
  P15EFParamDesc: '',
  P16EFParamDesc: '',
  P17EFParamDesc: '',
  P18EFParamDesc: '',
  P19EFParamDesc: '',
  P20EFParamDesc: '',
  P21EFParamDesc: '',
  P22EFParamDesc: '',
  P23EFParamDesc: '',
  P24EFParamDesc: '',
  P25EFParamDesc: '',
  P26EFParamDesc: '',
  P27EFParamDesc: '',
  P28EFParamDesc: '',
  P29EFParamDesc: '',
  P30EFParamDesc: '',
  P31EFParamDesc: '',
  P32EFParamDesc: '',
  P33EFParamDesc: '',
  P34EFParamDesc: '',
  eventsLog: 'LOG Eventos',
  events: 'Eventos',
  alarm_new_stop: 'Instrumento OFF',
  alarm_new_inputCadOff: 'Entrada OFF',
  alarm_new_flow: 'Alarma Flow',
  alarm_new_urOr: 'Alarma UR/OR',
  alarm_new_lowPHLvl: 'Alarma Min nivel pH',
  alarm_new_lowCl2Lvl: 'Alarma Min nivel Cl',
  alarm_new_maxPHDosage: 'Alarma Máx dosificación pH',
  alarm_new_maxCl2Dosage: 'Alarma Máx dosificación Cl',
  alarm_new_pHMin: 'Advert. min pH',
  alarm_new_phMax: 'Advert. máx pH',
  alarm_new_cl2Min: 'Advert. min Cl',
  alarm_new_cl2Max: 'Advert. máx Cl',
  alarm_new_clock: 'Timer',
  alarm_new_maxTimeK1: 'Alarma Máx T K1',
  alarm_new_maxTimeK2: 'Alarma Máx T K2',
  alarm_new_maxTimeK4: 'Alarma Máx T K4',
  alarm_new_maxTimeK5: 'Alarma Máx T K5',
  alarm_new_redox: 'Alarma Redox',
  alarm_new_zeroChlorine: 'Alarma cero cloro',
  output_mixer2: 'Mixer 2',
  alarm_extConsensus: 'Consenso esterno',
  alarm_lvl1MinAlarm: 'Alarma Min nivel 1',
  alarm_lvl2MinAlarm: 'Alarma Min nivel 2',
  alarm_lvl3MinAlarm: 'Alarma Min nivel 3',
  alarm_off: 'Off',
  alarm_new_maintenance: 'Manten. requerido',
  alarm_new_timer: 'Alarma Timer',
  alarm_new_lvl: 'Alarma Nivel',
  alarm_new_extcons: 'Alarma Ext.Cons',
  alarm_new_internalTemp: 'Alarma temperatura interna',
  alarm_new_clockKO: 'Alarma Reloj KO',
  alarm_new_sampleWater: 'Sample water',
  alarm_new_maxDoseTime: 'Alarma Max Dosific.',
  alarm_new_maintenancePump1: 'Mantenimiento B1',
  alarm_new_maintenancePump2: 'Mantenimiento B2',
  alarm_new_urOrMeasure1: 'Alarma UR/OR Medida 1',
  alarm_new_urOrMeasure2: 'Alarma UR/OR Medida 2',
  alarm_new_lvl1: 'Alarma Nivel 1',
  alarm_new_lvl2: 'Alarma Nivel 2',
  alarm_new_maxDosageTimeOut1: 'Alarma MAX T B1',
  alarm_new_maxDosageTimeOut2: 'Alarma MAX T B2',
  statuses: 'Estados',
  theme: 'tema',
  toolStatus: 'Estado de instrumento',
  minWarningLimit: 'Valor límite mín',
  maxWarningLimit: 'Valor límite máx',
  canNotSaveOfflineOrErrModule:
    'La configuración no se puede guardar cuando el módulo está fuera de línea o en error de comunicación.',
  ew2PoolInterfaceName: 'Evolution EW² Pool',
  ew2Pool_pHOrpTcInterfaceName: 'EW² Pool pH/ORP/T',
  ew2Pool_pHCacTcInterfaceName: 'EW² Pool pH/CAC/T',
  ew2Pool_pHCleTcInterfaceName: 'EW² Pool pH/CLE/T',
  nova2Pool_pHOrpTcInterfaceName: 'Nova² Pool pH/ORP/T',
  nova2Pool_pHCacTcInterfaceName: 'Nova² Pool pH/CAC/T',
  nova2Pool_pHCleTcInterfaceName: 'Nova² Pool pH/CLE/T',
  alarm_minThresholdM1: 'Umbral mínimo pH',
  alarm_maxThresholdM1: 'Umbral máximo pH',
  alarm_lvl1m1: 'Nivel 1 pH',
  alarm_lvl2m1: 'Nivel 2 pH',
  alarm_underRangeM1: 'Under Range pH',
  alarm_overRangeM1: 'Over Range pH',
  alarm_maxDosageM1: 'Máx dosificiación pH',
  alarm_minThresholdM2: 'Umbral mínimo Cl',
  alarm_maxThresholdM2: 'Umbral máximo Cl',
  alarm_lvl1m2: 'Nivel 1 Cl',
  alarm_lvl2m2: 'Nivel 2 Cl',
  alarm_underRangeM2: 'Under Range Cl',
  alarm_overRangeM2: 'Over Range Cl',
  alarm_maxDosageM2: 'Máx dosificación Cl',
  alarm_sampleFlow: 'Flujo agua de muestra',
  alarm_pHPriority: 'pH priority',
  output_pfmM1: 'OUT PFM pH',
  output_pfmM2: 'OUT PFM Cl',
  output_outM1: 'OUT pH',
  output_flocculant: 'OUT FLOCULANTE',
  output_outM2: 'OUT Cl',
  output_antiAlgae: 'OUT ANTIALGAS',
  output_poolRelayAlarm: 'RELE’ ALARMA',
  input_lvl1m1: 'EN Nivel 1 pH',
  input_lvl2m1: 'EN Nivel 2 pH',
  input_externalConsent: 'EN Consentimiento ext.',
  input_ecoMode: 'EN Eco Mode',
  input_sampleWater: 'EN Sample Water',
  input_lvl1m2: 'EN Nivel 1 Cl',
  input_lvl2m2: 'EN Nivel 2 Cl',
  setpointM1OnOffParamDesc: 'Setpoint pH On/Off',
  propBandM1OnOffParamDesc: 'Histéresis pH On/Off',
  dosageDirM1OnOffParamDesc: 'Dirección dosific. pH On/Off',
  maxDosageTimeM1OnOffParamDesc: 'Tiempo MAX Dosific. pH On/Off',
  setpointM1PwmParamDesc: 'Setpoint pH PWM',
  propBandM1PwmParamDesc: 'Banda proporcional pH PWM',
  dosageDirM1PwmParamDesc: 'Dirección dosific. pH PWM',
  maxDosageTimeM1PwmParamDesc: 'Tiempo MAX Dosific. pH PWM',
  setpointM2OnOffParamDesc: 'Setpoint Cl On/Off',
  setpointM2PwmParamDesc: 'Setpoint Cl PWM',
  setpointM1PfmParamDesc: 'Setpoint pH PFM',
  propBandM1PfmParamDesc: 'Banda proporcional pH PFM',
  dosageDirM1PfmParamDesc: 'Dirección dosific. pH PFM',
  maxDosageTimeM1PfmParamDesc: 'Tiempo MAX Dosific. pH PFM',
  minFreqM1ParamDesc: 'Frecuencia mínima pH',
  maxFreqM1ParamDesc: 'Frecuencia máxima pH',
  minLimitM1ParamDesc: 'Límite MIN pH',
  maxLimitM1ParamDesc: 'Límite MAX pH',
  propBandM2OnOffParamDesc: 'Histéresis Cl On/Off',
  dosageDirM2OnOffParamDesc: 'Dirección dosific. Cl On/Off',
  maxDosageTimeM2OnOffParamDesc: 'Tiempo MAX Dosific. Cl On/Off',
  propBandM2PwmParamDesc: 'Banda proporcional Cl PWM',
  dosageDirM2PwmParamDesc: 'Dirección dosific. Cl PWM',
  maxDosageTimeM2PwmParamDesc: 'Tiempo MAX Dosific. Cl PWM',
  setpointM2PfmParamDesc: 'Setpoint Cl PFM',
  propBandM2PfmParamDesc: 'Banda proporcional Cl PFM',
  dosageDirM2PfmParamDesc: 'Dirección dosific. Cl PFM',
  maxDosageTimeM2PfmParamDesc: 'Tiempo MAX Dosific. Cl PFM',
  minFreqM2ParamDesc: 'Frecuencia mínima Cl',
  maxFreqM2ParamDesc: 'Frecuencia máxima Cl',
  setpointEcoModeParamDesc: 'Setpoint Eco/Boost Mode',
  udmTempParamDesc: 'UDM Temperatura',
  maxLimitM2ParamDesc: 'Límite MAX Cl',
  minLimitM2ParamDesc: 'Límite MIN Cl',
  acidification: 'Acidificación',
  alkalization: 'Alcalinización',
  dechlorination: 'Descloración',
  chlorination: 'Cloración',
  output_out230VM2: 'OUT_230V Cl',
  output_out230VM1: 'OUT_230V pH',
  output_phMagnet: 'Status imán pH',
  output_clMagnet: 'Status imán Cl',
  admin2: 'Distribuidor',
  referent: 'Referente',
  externalConsent: 'Consentimiento ext.',
  ecoMode: 'Eco-Mode',
  companyName: 'Nombre de empresa',
  invalidNameOrCompanyName: 'Establecer un nombre/apellido o nombre de empresa',
  alarm_sampleFlowM1: 'flusso campione M1',
  alarm_sampleFlowM2: 'flusso campione M2',
  output_out1M1: 'OUT1 M2', // STEIEL-161 OUT1 M1
  output_out2M1: 'OUT2 M2', // STEIEL-161 OUT2 M1
  output_out1M2: 'OUT1 M1', // STEIEL-161 OUT1 M2
  output_out2M2: 'OUT2 M1', // STEIEL-161 OUT2 M2
  output_pfm1M1: 'PFM1 M1',
  output_pfm2M1: 'PFM2 M1',
  output_pfm1M2: 'PFM1 M2',
  output_pfm2M2: 'PFM2 M2',
  input_sampleWaterM2: 'Samplewater M2',
  ew2InterfaceName: 'EW²',
  input_ew2_lvl1m1: 'Livello 1 M1',
  input_ew2_lvl2m1: 'Livello 2 M1',
  input_ew2_externalConsent: 'Abilitazione esterna',
  input_ew2_ecoMode: 'Eco/boost mode',
  input_ew2_sampleWater: 'Samplewater M1',
  input_ew2_sampleWaterM2: 'Samplewater M2',
  input_ew2_lvl1m2: 'Livello 1 M2',
  input_ew2_lvl2m2: 'Livello 2 M2',
  input_waterMeter: 'Water meter',
  alarm_ew2_minThresholdM1: 'soglia minima M1',
  alarm_ew2_maxThresholdM1: 'soglia massima M1',
  alarm_ew2_lvl1m1: 'level 1 M1',
  alarm_ew2_lvl2m1: 'level 2 M1',
  alarm_ew2_underRangeM1: 'under range M1',
  alarm_ew2_overRangeM1: 'over range M1',
  alarm_ew2_maxDosageM1: 'massimo dosaggio M1',
  alarm_ew2_sampleFlowM1: 'flusso campione M1',
  alarm_ew2_minThresholdM2: 'soglia minima M2',
  alarm_ew2_maxThresholdM2: 'soglia massima M2',
  alarm_ew2_lvl1m2: 'level 1 M2',
  alarm_ew2_lvl2m2: 'level 2 M2',
  alarm_ew2_underRangeM2: 'under range M2',
  alarm_ew2_overRangeM2: 'over range M2',
  alarm_ew2_maxDosageM2: 'massimo dosaggio M2',
  alarm_ew2_sampleFlowM2: 'flusso campione M2',
  P01EW2ParamDesc: 'Config. OUT1 M1',
  P02EW2ParamDesc: 'Setpoint OUT1 OnOff M1',
  P03EW2ParamDesc: 'Setpoint OUT1 PWM M1',
  P04EW2ParamDesc: 'Int. Lavoro OUT1 Soglia1 M1',
  P05EW2ParamDesc: 'Int. Lavoro OUT1 Soglia2 M1',
  P06EW2ParamDesc: 'Config. OUT2 M1',
  P07EW2ParamDesc: 'Setpoint OUT2 OnOff M1',
  P08EW2ParamDesc: 'Setpoint OUT2 PWM M1',
  P09EW2ParamDesc: 'Int. Lavoro OUT2 Soglia1 M1',
  P10EW2ParamDesc: 'Int. Lavoro OUT2 Soglia2 M1',
  P11EW2ParamDesc: 'Config. OUT3 M1',
  P12EW2ParamDesc: 'Setpoint OUT3 PFM M1',
  P13EW2ParamDesc: 'Frequenza min OUT3 M1',
  P14EW2ParamDesc: 'Frequenza max OUT3 M1',
  P15EW2ParamDesc: 'Config. OUT4 M1',
  P16EW2ParamDesc: 'Setpoint OUT4 PFM M1',
  P17EW2ParamDesc: 'Frequenza min OUT4 M1',
  P18EW2ParamDesc: 'Frequenza max OUT4 M1',
  P19EW2ParamDesc: 'Config. OUT-mA M1',
  P20aEW2ParamDesc: 'Scala OUT-mA M1',
  P20bEW2ParamDesc: 'Scala OUT-mA M1',
  P21EW2ParamDesc: 'Setpoint OUT-mA M1 (Reg.)',
  P22EW2ParamDesc: 'Limite min M1 ',
  P23EW2ParamDesc: 'Limite max M1 ',
  P24EW2ParamDesc: 'Setpoint Eco/Boost mode OUT1 M1',
  P25EW2ParamDesc: 'Setpoint Eco/Boost mode OUT2 M1',
  P26EW2ParamDesc: 'Setpoint Eco/Boost mode OUT-mA M1',
  P27EW2ParamDesc: 'Config. OUT1 M2',
  P28EW2ParamDesc: 'Setpoint OUT1 OnOff M2',
  P29EW2ParamDesc: 'Setpoint OUT1 PWM M2',
  P30EW2ParamDesc: 'Int. Lavoro OUT1 Soglia1 M2',
  P31EW2ParamDesc: 'Int. Lavoro OUT1 Soglia2 M2',
  P32EW2ParamDesc: 'Config. OUT2 M2',
  P33EW2ParamDesc: 'Setpoint OUT2 OnOff M2',
  P34EW2ParamDesc: 'Setpoint OUT2 PWM M2',
  P35EW2ParamDesc: 'Int. Lavoro OUT2 Soglia1 M2',
  P36EW2ParamDesc: 'Int. Lavoro OUT2 Soglia2 M2',
  P37EW2ParamDesc: 'Config. OUT3 M2',
  P38EW2ParamDesc: 'Setpoint OUT3 PFM M2',
  P39EW2ParamDesc: 'Frequenza min OUT3 M2',
  P40EW2ParamDesc: 'Frequenza max OUT3 M2',
  P41EW2ParamDesc: 'Config. OUT4 M2',
  P42EW2ParamDesc: 'Setpoint OUT4 PFM M2',
  P43EW2ParamDesc: 'Frequenza min OUT4 M2',
  P44EW2ParamDesc: 'Frequenza max OUT4 M2',
  P45EW2ParamDesc: 'Config. OUT-mA M2',
  P46aEW2ParamDesc: 'Scala OUT-mA M2',
  P46bEW2ParamDesc: 'Scala OUT-mA M2',
  P47EW2ParamDesc: 'Setpoint OUT-mA M2 (Reg.)',
  P48EW2ParamDesc: 'Limite min M2',
  P49EW2ParamDesc: 'Limite max M2',
  P50EW2ParamDesc: 'Setpoint Eco/Boost mode OUT1 M2',
  P51EW2ParamDesc: 'Setpoint Eco/Boost mode OUT2 M2',
  P52EW2ParamDesc: 'Setpoint Eco/Boost mode OUT-mA M2',
  P53EW2ParamDesc: 'Isteresi OUT1 OnOff M1',
  P54EW2ParamDesc: 'Banda Proporzionale OUT1 PWM M1',
  P55EW2ParamDesc: 'Direzione Dosaggio OUT1 OnOff M1',
  P56EW2ParamDesc: 'Direzione Dosaggio OUT1 PWM M1',
  P57EW2ParamDesc: 'Tempo MAX Dosaggio OUT1 OnOff M1',
  P58EW2ParamDesc: 'Tempo MAX Dosaggio OUT1 PWM M1',
  P59EW2ParamDesc: 'Stato tra le soglie OUT1 (Int. lavoro) M1',
  P60EW2ParamDesc: 'Isteresi OUT2 OnOff M1',
  P61EW2ParamDesc: 'Banda Proporzionale OUT2 PWM M1',
  P62EW2ParamDesc: 'Direzione Dosaggio OUT2 OnOff M1',
  P63EW2ParamDesc: 'Direzione Dosaggio OUT2 PWM M1',
  P64EW2ParamDesc: 'Tempo MAX Dosaggio OUT2 OnOff M1',
  P65EW2ParamDesc: 'Tempo MAX Dosaggio OUT2 PWM M1',
  P66EW2ParamDesc: 'Stato tra le soglie OUT2 (Int. lavoro) M1',
  P67EW2ParamDesc: 'Banda Proporzionale OUT3 PFM M1',
  P68EW2ParamDesc: 'Direzione Dosaggio OUT3 PFM M1',
  P69EW2ParamDesc: 'Tempo MAX Dosaggio OUT3 PFM M1',
  P70EW2ParamDesc: 'Banda Proporzionale OUT4 PFM M1',
  P71EW2ParamDesc: 'Direzione Dosaggio OUT4 PFM M1',
  P72EW2ParamDesc: 'Tempo MAX Dosaggio OUT4 PFM M1',
  P73EW2ParamDesc: 'Valore 0/4 mA OUT-mA M1',
  P74EW2ParamDesc: 'Valore 20 mA OUT-mA M1',
  P75EW2ParamDesc: 'Banda Proporzionale OUT-mA M1',
  P76EW2ParamDesc: 'Direzione Dosaggio OUT-mA M1',
  P77EW2ParamDesc: 'Tempo MAX Dosaggio OUT-mA M1',
  P78EW2ParamDesc: 'Isteresi OUT1 OnOff M2',
  P79EW2ParamDesc: 'Banda Proporzionale OUT1 PWM M2',
  P80EW2ParamDesc: 'Direzione Dosaggio OUT1 OnOff M2',
  P81EW2ParamDesc: 'Direzione Dosaggio OUT1 PWM M2',
  P82EW2ParamDesc: 'Tempo MAX Dosaggio OUT1 OnOff M2',
  P83EW2ParamDesc: 'Tempo MAX Dosaggio OUT1 PWM M2',
  P84EW2ParamDesc: 'Stato tra le soglie OUT1 (Int. lavoro) M2',
  P85EW2ParamDesc: 'Isteresi OUT2 OnOff M2',
  P86EW2ParamDesc: 'Banda Proporzionale OUT2 pwm M2',
  P87EW2ParamDesc: 'Direzione Dosaggio OUT2 OnOff M2',
  P88EW2ParamDesc: 'Direzione Dosaggio OUT2 PWM M2',
  P89EW2ParamDesc: 'Tempo MAX Dosaggio OUT2 OnOff M2',
  P90EW2ParamDesc: 'Tempo MAX Dosaggio OUT2 PWM M2',
  P91EW2ParamDesc: 'Stato tra le soglie OUT2 (Int. lavoro) M2',
  P92EW2ParamDesc: 'Banda Proporzionale OUT3 PFM M2',
  P93EW2ParamDesc: 'Direzione Dosaggio OUT3 PFM M2',
  P94EW2ParamDesc: 'Tempo MAX Dosaggio OUT3 PFM M2',
  P95EW2ParamDesc: 'Banda Proporzionale OUT4 PFM M2',
  P96EW2ParamDesc: 'Direzione Dosaggio OUT4 PFM M2',
  P97EW2ParamDesc: 'Tempo MAX Dosaggio OUT4 PFM M2',
  P98EW2ParamDesc: 'Valore 0/4 mA OUT-mA M2',
  P99EW2ParamDesc: 'Valore 20 mA OUT-mA M2',
  P100EW2ParamDesc: 'Banda Proporzionale OUT-mA M2',
  P101EW2ParamDesc: 'Direzione Dosaggio OUT-mA M2',
  P102EW2ParamDesc: 'Tempo MAX Dosaggio OUT-mA M2',
  repetition: 'ripetizione',
  regulation: 'regulation',
  acidificationDechlorinationReduction: 'Acidificazione/Declorazione/Riduzione',
  alkalizationChlorinationOxidation: 'Alcalinizzazione/Clorazione/Ossidazione',
  workInt: 'Int. di lavoro',
};
